import React, { FC, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { colorHexToRgba } from '../../../helpers';

export const ScrollCss = css`
  ::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.color.white};
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.aqua};
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.8)};
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
  }
`;

const Container = styled.div`
  overflow: auto;
  ${ScrollCss}
  padding: 10px;
  &.flex-fill {
    flex: 1;
  }
`;

const ScrollContainer: FC<{
  className?: string;
  flexFill?: boolean;
  onScroll?: (bottomOffset: number) => void;
}> = ({ children, className, onScroll, flexFill = false }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!onScroll || !containerRef.current) {
      return () => {};
    }

    const containerElement = containerRef.current;

    const onContainerScroll = () => {
      const { clientHeight, scrollTop, scrollHeight } = containerElement;

      onScroll(scrollHeight - (clientHeight + scrollTop));
    };

    containerElement.addEventListener('scroll', onContainerScroll);

    return () => {
      onScroll &&
        containerElement &&
        containerElement.removeEventListener('scroll', onContainerScroll);
    };
  }, [onScroll]);

  return (
    <Container
      data-testid="scroll-container"
      ref={containerRef}
      className={`scroll-container ${className}${flexFill ? ' flex-fill' : ''}`}
    >
      {children}
    </Container>
  );
};

export { ScrollContainer };
