import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { TutorialActions, UserActions } from '../actions';
import { useAppDispatch } from './useAppDispatch';
import { useModal } from './useModal';

interface TutorialRouteParams {
  productId: string;
  serviceName: string;
  section?: string;
}

const tutorialParamsSelector = ({
  tutorial: {
    formSpec,
    error,
    serviceName: tutorialServiceName,
    isLoadingFormSpec,
  },
  anonymousUser: { anonymousToken },
}: STATES.AppState) => ({
  formSpec,
  error,
  tutorialServiceName,
  isLoadingFormSpec,
  anonymousToken,
});

const useTutorialParams = (ignoreError?: boolean) => {
  const dispatch = useAppDispatch();
  const { productId, serviceName, section } = useParams<TutorialRouteParams>();
  const queryStringParams = queryString.parse(window.location.search);
  const code = queryStringParams?.code as string | undefined;
  let isAnonymousUser = queryStringParams?.isAnonymousUser as
    | boolean
    | undefined;
  const anonymousUserId = queryStringParams?.anonymousUserId as
    | string
    | undefined;
  const linkId = queryStringParams?.linkId as string | undefined;
  const client = queryStringParams?.client as string | undefined;
  const [checkIfUserAnonymous, setCheckIfUserAnonymous] = useState<boolean>(
    false
  );
  const realm = queryStringParams?.realm as string | undefined;
  realm && localStorage.setItem('Tenant', realm);
  const {
    formSpec,
    error,
    tutorialServiceName,
    isLoadingFormSpec,
    anonymousToken,
  } = useSelector(tutorialParamsSelector);
  const errorRef = useRef('');
  const { showInfo } = useModal();
  const ANONYMOUS_AUTH_TOKEN = 'ANONYMOUS_AUTH_TOKEN';

  let tutorialUrl = '';
  if (code || isAnonymousUser) {
    isAnonymousUser = true;
    tutorialUrl = `/products/${productId}/tutorial/${serviceName}/anonymous/calculator`;
  } else {
    tutorialUrl = `/products/${productId}/tutorial/${serviceName}/calculator`;
  }

  const storedAnonymousAuthToken = localStorage.getItem(ANONYMOUS_AUTH_TOKEN);
  if (storedAnonymousAuthToken && isAnonymousUser && !checkIfUserAnonymous) {
    dispatch(UserActions.setAnonymousIdToken(storedAnonymousAuthToken));
    setCheckIfUserAnonymous(true);
  }

  useEffect(() => {
    if (
      !productId ||
      !serviceName ||
      ((isLoadingFormSpec || error) && serviceName === tutorialServiceName)
    ) {
      return;
    }

    if (
      (serviceName !== tutorialServiceName || !formSpec) &&
      checkIfUserAnonymous
    ) {
      dispatch(TutorialActions.getFormSpec(productId, serviceName));
    }
  }, [
    dispatch,
    productId,
    serviceName,
    tutorialServiceName,
    isLoadingFormSpec,
    formSpec,
    error,
    checkIfUserAnonymous,
  ]);

  useEffect(() => {
    if (anonymousToken) {
      localStorage.removeItem(ANONYMOUS_AUTH_TOKEN);
      localStorage.setItem(ANONYMOUS_AUTH_TOKEN, anonymousToken.toString());
      dispatch(UserActions.setAnonymousIdToken(anonymousToken.toString()));
      setCheckIfUserAnonymous(true);
    }
  }, [anonymousToken, dispatch]);

  useEffect(() => {
    if (code && anonymousUserId && linkId && client) {
      dispatch(
        UserActions.generateAnonymousKeyCloakUserToken(
          anonymousUserId,
          linkId,
          code,
          client
        )
      );
    } else {
      setCheckIfUserAnonymous(true);
    }
  }, [code, anonymousUserId, linkId, client, dispatch]);

  const getFormStateByFields = useCallback(
    async (fields: DTO.FormStateInputField[]) => {
      if (!productId || !serviceName || !formSpec || !formSpec.isConstructed) {
        return { fields: {} };
      }

      const formstate = await dispatch(
        TutorialActions.getFormState(productId, serviceName, fields)
      );

      return formstate;
    },
    [dispatch, productId, serviceName, formSpec]
  );

  useEffect(() => {
    if (errorRef.current === error) {
      return;
    }

    if (error && !ignoreError) {
      showInfo({
        content: error,
        okText: 'Global.ok',
      });
    }

    errorRef.current = error;
  }, [error, showInfo, ignoreError]);

  return {
    productId,
    serviceName,
    section,
    formSpec,
    getFormStateByFields,
    tutorialUrl,
    isLoadingFormSpec,
    isAnonymousUser,
  };
};

export { useTutorialParams };
