const stringCompareFunc = (str1: string, str2: string) => {
  if (str1 === str2) {
    return 0;
  }

  if (str1 < str2) {
    return -1;
  }

  return 1;
};

export { stringCompareFunc };
