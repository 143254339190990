import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { AlertActions, ProductsActions, EngineActions } from '../actions';
import { useModal } from './useModal';

const alertSelector = ({
  alertMessage: { showAlertModal, contentKey },
}: STATES.AppState) => ({
  showAlertModal,
  contentKey,
});

const useHostGlobalAlert = () => {
  const { showAlertModal, contentKey } = useSelector(alertSelector);
  const contentKeyRef = useRef(contentKey);
  const dispatch = useDispatch();
  const { showInfo } = useModal();
  const modalRef = useRef<ReturnType<typeof showInfo> | null>(null);

  useEffect(() => {
    if (showAlertModal) {
      if (contentKeyRef.current === contentKey) {
        return;
      }

      if (modalRef.current) {
        modalRef.current.destroy();
      }

      modalRef.current = showInfo({
        content: <FormattedHTMLMessage id={contentKey} />,
        onOk: () => {
          dispatch(ProductsActions.cancelEditProduct());
          dispatch(ProductsActions.cancelUploadEngine());
          dispatch(ProductsActions.cancelEditCoverImage());
          dispatch(ProductsActions.cancelUploadDocumentModal());
          dispatch(EngineActions.cancelUpdateConfirm());
          dispatch(EngineActions.cancelPublishEngine());
          dispatch(ProductsActions.cancelUploadEngine());
          dispatch(ProductsActions.cancelNewProduct());
          dispatch(AlertActions.closeGlobalModal());

          modalRef.current = null;
        },
      });
    } else {
      modalRef.current = null;
    }

    contentKeyRef.current = contentKey;
  }, [showAlertModal, contentKey, showInfo, dispatch]);
};

export { useHostGlobalAlert };
