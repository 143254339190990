import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SystemActions } from '../actions';

const productDocMetadataSelector = ({
  system: { isLoadingProductDocMetadata, productDocMetadata },
}: STATES.AppState) => ({
  isLoadingProductDocMetadata,
  productDocMetadata,
});

const useProductDocMetadata = () => {
  const dispatch = useDispatch();
  const { isLoadingProductDocMetadata, productDocMetadata } = useSelector(
    productDocMetadataSelector
  );

  useEffect(() => {
    if (productDocMetadata?.length === 0) {
      dispatch(SystemActions.getProductDocumentMetadata());
    }
    // Only run on didMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return {
    productDocMetadata,
    isLoadingProductDocMetadata,
  };
};

export { useProductDocMetadata };
