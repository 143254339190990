import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

interface FloatingButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  icon?: string;
}

const StyledFloatButton = styled.button`
  height: 80px;
  width: 80px;
  outline: 0;
  background: none;
  border: none;

  .anticon {
    color: ${({ theme }) => theme.vars.primaryColor};
    font-size: 54px;
  }
`;

const StyledFloatContainer = styled.div`
  position: fixed;
  bottom: 40px;
  right: 85px;
  cursor: pointer;
`;

const FloatingButton: React.FC<FloatingButtonProps> = ({ icon }) => {
  const [showBtn, setDisplay] = useState(false);

  const onClick = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset >= 400) {
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return showBtn ? (
    <StyledFloatContainer>
      <StyledFloatButton onClick={onClick}>
        <Icon type={icon} />
      </StyledFloatButton>
    </StyledFloatContainer>
  ) : null;
};

export { FloatingButton };
