import { UserConstants } from '../constants';
import { UserAction, UserThunkAction } from './types';
import { FileManagerService, UserService } from '../services';
import { ApiError, getValidRoleAndGroup } from '../helpers';
import { AlertActions } from './alert.actions';
import {
  getDisplayName,
  isAdmin,
  isSuperuser,
  verifyToken,
  getUserEmailId,
  getUserName,
  decodeToken,
} from '../helpers/user-auth';

const exchangeToken = (
  code: string,
  redirectUrl: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.LOGIN_REQUEST,
      payload: { remember: true },
    });

    const { payload } = await UserService.exchangeToken(code, redirectUrl);

    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;

    let decodedJwt = decodeToken({
      id_token: data.access_token,
    });

    let issuerConfig: DTO.IssuerConfiguration | null = null;
    if (decodedJwt && decodedJwt.iss) {
      issuerConfig = await UserService.getIssuerConfiguration(decodedJwt.iss);
    }

    if (decodedJwt && issuerConfig && !issuerConfig.verificationEnabled) {
      decodedJwt = { ...decodedJwt, groups: issuerConfig.groups };
    } else {
      decodedJwt = await verifyToken({
        id_token: data.access_token,
      });
    }

    if (
      decodedJwt &&
      decodedJwt.groups &&
      getValidRoleAndGroup(decodedJwt.groups).group
    ) {
      dispatch({
        type: UserConstants.LOGIN_SUCCESS,
        payload: {
          groups: decodedJwt.groups,
          userAuth: {
            id_token: data.access_token,
            refresh_token: data.refresh_token,
            username: getUserName(decodedJwt, issuerConfig),
          },
          userInfo: {
            displayName: getDisplayName(decodedJwt, issuerConfig),
            userEmailID: getUserEmailId(decodedJwt, issuerConfig),
          },
          userId: decodedJwt.sub,
          isAdmin: isAdmin(decodedJwt),
          isSuperuser: isSuperuser(decodedJwt, issuerConfig),
          tenant: issuerConfig?.tenant ?? decodedJwt.realm,
        },
      });

      return;
    }

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { reason: 'UnauthorizedUserGroup' },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const validateTokens = (
  authToken: string,
  refreshToken: string,
  username: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.LOGIN_REQUEST,
      payload: { remember: false },
    });

    let decodedJwt = decodeToken({
      id_token: authToken,
    });

    let issuerConfig: DTO.IssuerConfiguration | null = null;
    if (decodedJwt && decodedJwt.iss) {
      issuerConfig = await UserService.getIssuerConfiguration(decodedJwt.iss);
    }

    if (decodedJwt && issuerConfig && !issuerConfig.verificationEnabled) {
      decodedJwt = { ...decodedJwt, groups: issuerConfig.groups };
    } else {
      decodedJwt = await verifyToken({
        id_token: authToken,
      });
    }

    if (
      decodedJwt &&
      decodedJwt.groups &&
      getValidRoleAndGroup(decodedJwt.groups).group
    ) {
      dispatch({
        type: UserConstants.LOGIN_SUCCESS,
        payload: {
          groups: decodedJwt.groups,
          userAuth: {
            id_token: authToken,
            refresh_token: refreshToken,
            username,
          },
          userInfo: {
            displayName: getDisplayName(decodedJwt, issuerConfig),
            userEmailID: username,
          },
          userId: decodedJwt.sub,
          isAdmin: isAdmin(decodedJwt),
          isSuperuser: isSuperuser(decodedJwt, issuerConfig),
          tenant: issuerConfig?.tenant ?? decodedJwt.realm,
        },
      });

      return;
    }

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { reason: 'UnauthorizedUserGroup' },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const createKeyCloakAnonymousUser = (
  anonymousUser: DTO.KeyCloakAnonymousUser
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.createKeyCloakAnonymousUser(
      anonymousUser
    );

    if (!payload || payload.status !== 'Success') {
      if (
        payload?.errorCode === 'INVALID_INPUT' ||
        payload?.errorCode === 'MISSING_KEY_IN_BODY' ||
        payload?.errorCode === 'INVALID_DATETIME'
      ) {
        throw new ApiError({
          error_code: 'INVALID_INPUT_ANONYMOUSUSER',
        });
      } else {
        throw new ApiError(payload);
      }
    }

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_USER_SUCCESS,
      payload: {
        userId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const createLinkResource = (
  linkResource: DTO.LinkResourceDetail
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await FileManagerService.createLinkResource(
      linkResource
    );

    if (!payload || payload.status !== 'Success') {
      switch (payload.error_code) {
        case 'PERMISSION_DENIED': {
          throw new ApiError({
            error_code: 'PERMISSION_DENIED_ANONYMOUSLINK',
          });
        }
        case 'USER_NOT_FOUND': {
          throw new ApiError({
            error_code: 'USER_NOT_FOUND_ANONYMOUSLINK',
          });
        }
        default: {
          throw new ApiError(payload);
        }
      }
    }

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_LINK_SUCCESS,
      payload: {
        linkUrl: '',
        linkId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const sendOtpToAnonymousKeyCloakUser = (
  anonymousUserId: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.sendOTPToAnonymousUser(
      anonymousUserId
    );

    if (!payload || payload.status !== 'Success') {
      if (payload?.errorCode === 'USER_NOT_FOUND') {
        throw new ApiError({
          error_code: 'USER_NOT_FOUND_ANONYMOUSLINK',
        });
      } else {
        throw new ApiError(payload);
      }
    }

    dispatch({
      type: UserConstants.SEND_KEYCLOAK_OTP_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const generateAnonymousKeyCloakUserToken = (
  anonymousUserId: string,
  linkId: string,
  code: string,
  client: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.generateAnonymouseUserToken(
      anonymousUserId,
      linkId,
      code,
      client
    );

    if (!payload || payload.status !== 'Success') {
      if (payload?.errorCode === 'MISSING_PARAMETERS') {
        throw new ApiError({
          error_code: 'INVALID_INPUT_ANONYMOUSUSER',
        });
      } else if (payload?.errorCode === 'USER_NOT_FOUND') {
        throw new ApiError({
          error_code: 'USER_NOT_FOUND_ANONYMOUSLINK',
        });
      } else {
        throw new ApiError(payload);
      }
    }

    dispatch({
      type: UserConstants.GENERATE_KEYCLOAK_ANONYMOUS_TOKEN_SUCCESS,
      payload: {
        redirectUrl: payload.data.targetUrl,
        anonymousToken: payload.data.access_token,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const logout = (
  issuerConfig: DTO.IssuerConfiguration | null,
  returnUrl?: string
): UserAction => {
  const logOutUrl = UserService.getLogoutUrl(issuerConfig, returnUrl);
  return {
    type: UserConstants.LOGOUT,
    payload: { logOutUrl: logOutUrl ?? '' },
  };
};

const loggedOut = (): UserAction => {
  return { type: UserConstants.LOGGED_OUT };
};

const loginClean = (): UserAction => {
  return { type: UserConstants.LOGIN_CLEAN };
};

const setIdToken = (id_token: string): UserAction => ({
  type: UserConstants.SET_ID_TOKEN,
  payload: {
    id_token,
  },
});

const setAnonymousIdToken = (token: string): UserAction => ({
  type: UserConstants.SET_ANONYMOUS_ID_TOKEN,
  payload: {
    id_token: token,
  },
});

const getIssuerConfiguration = (
  issuer?: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.ISSUER_CONFIGURATIONS_REQUEST });
    const issuerConfig = await UserService.getIssuerConfiguration(issuer);
    dispatch({
      type: UserConstants.ISSUER_CONFIGURATIONS,
      payload: { issuerConfig },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.ISSUER_CONFIGURATIONS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

export const UserActions = {
  logout,
  loggedOut,
  setIdToken,
  loginClean,
  setAnonymousIdToken,
  validateTokens,
  exchangeToken,
  createKeyCloakAnonymousUser,
  sendOtpToAnonymousKeyCloakUser,
  generateAnonymousKeyCloakUserToken,
  createLinkResource,
  getIssuerConfiguration,
};
