import makeRequest from '../helpers/make-request';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getReleaseDetails = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/release`;
  return makeRequest<DTO.ReleaseDetailsResponse>('GET', url);
};

const getLookupData = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/lookup/GetLookupData`;
  return makeRequest<DTO.GetLookupDataResponse>('GET', url);
};

const getProductDocumentMetadata = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/config/GetProductDocumentMetadata`;
  return makeRequest<DTO.GetProductDocMetadataResponse>('GET', url);
};

const getDiagnoseDetails = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/Diagnostic/get`;
  return makeRequest<DTO.DiagnoseDetailsResponse>('GET', url);
};

const clearCacheMemory = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/Diagnostic/ClearCache`;
  return makeRequest<DTO.DiagnoseDetailsResponse>('POST', url);
};

const clearApplicationMemory = () => {
  const url = `${EXCELENGINE_DOMAIN}/api/Diagnostic/ClearGC`;
  return makeRequest<DTO.DiagnoseDetailsResponse>('POST', url);
};

export const SystemService = {
  getDiagnoseDetails,
  getReleaseDetails,
  getLookupData,
  clearCacheMemory,
  clearApplicationMemory,
  getProductDocumentMetadata,
};
