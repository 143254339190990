import { ApiError } from './errors';

const TypeErrorCodeMap = {
  cancelled: 'NETWORK_CANCELLED',
  'failed to fetch': 'NETWORK_ERROR',
};

const fetchNetworkErrorWrap = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  try {
    const response = await fetch(input, init);

    return response;
  } catch (error) {
    if (error instanceof TypeError) {
      throw new ApiError({
        error_code: TypeErrorCodeMap[error.message.toLocaleLowerCase()] || '',
      });
    }

    throw error;
  }
};

export default fetchNetworkErrorWrap;
