import React, { FC } from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';
import Svgs from '../../../assets/Svgs';

const IconSvg: FC<IconProps> = ({ type, className, ...remaining }) => {
  if (type && Svgs[type]) {
    return (
      <Icon
        component={Svgs[type]}
        className={`${type} ${className || ''}`}
        {...remaining}
      />
    );
  }

  return <Icon className={className} type={type} {...remaining} />;
};

export { IconSvg };
