const fileToBase64 = (file: File, removeMetadata = true) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      let result = reader.result?.toString();

      if (result && removeMetadata) {
        result = result.replace(/^data:.*;base64,/i, '');
      }

      resolve(result);
    };
    reader.onerror = error => reject(error);

    reader.readAsDataURL(file);
  });

const loadImage = (
  image: HTMLImageElement,
  file: File | Blob,
  callback?: () => void
) => {
  if (typeof URL === 'undefined') {
    const reader = new FileReader();
    reader.onload = evt => {
      const result = evt?.target?.result;

      if (result && typeof result === 'string') {
        image.src = result;
      }

      callback && callback();
    };
    reader.readAsDataURL(file);
  } else {
    image.src = URL.createObjectURL(file);
    image.addEventListener('load', () => {
      callback && callback();
    });
  }
};

const shrinkImage = (file: File | Blob, maxFileSize: number) =>
  new Promise<Blob>((resolve, reject) => {
    const image = document.createElement('img');

    image.onload = () => {
      const { width, height } = image;

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(image, 0, 0, width, height);

      canvas.toBlob(
        blob => {
          canvas.remove();
          image.remove();

          if (!blob) {
            reject();
            return;
          }

          if (blob.size > maxFileSize) {
            shrinkImage(blob, maxFileSize)
              .then(resolve)
              .catch(reject);
            return;
          }

          resolve(blob);
        },
        'image/jpeg',
        0.8
      );
    };

    loadImage(image, file);
  });

export { fileToBase64, shrinkImage };
