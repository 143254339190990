import { useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EngineActions } from '../actions';
import { useAppDispatch } from './useAppDispatch';

const mapStateToProps = ({
  productEngines: {
    selectedEngine,
    selectedEngineName,
    generatedTestCasesFile,
    generatedTestCasesFileName,
    generatingTestCases,
  },
}: STATES.AppState) => ({
  selectedEngine,
  selectedEngineName,
  generatedTestCasesFile,
  generatedTestCasesFileName,
  generatingTestCases,
});

const useTestGenerate = (productId: string) => {
  const [testCasesProgress, setTestCasesProgress] = useState<number>(0);
  const testCasesProgressIntervalRef = useRef<number>(0);
  const dispatch = useAppDispatch();
  const {
    selectedEngine,
    generatedTestCasesFile,
    generatedTestCasesFileName,
    selectedEngineName,
  } = useSelector(mapStateToProps);

  const testGenerateTestParams = useRef<{
    inputs: DTO.NameValueRangeInput[];
    numberOfTestCases: number;
  }>({
    inputs: [],
    numberOfTestCases: 0,
  });

  const { numberOfTestCases, inputs } = testGenerateTestParams.current;

  useEffect(() => {
    if (testCasesProgress === 100) {
      clearInterval(testCasesProgressIntervalRef.current);
      setTestCasesProgress(100);
    }
  }, [testCasesProgress]);

  const submitTestCaseInputs = useCallback(async () => {
    if (!selectedEngine) {
      return;
    }

    setTestCasesProgress(5);
    clearInterval(testCasesProgressIntervalRef.current);

    testCasesProgressIntervalRef.current = setInterval(() => {
      setTestCasesProgress(current => {
        if (current === 90) {
          clearInterval(testCasesProgressIntervalRef.current);

          return current;
        }

        return current + 5;
      });
    }, 1000);

    await dispatch(
      EngineActions.generateTestCases(
        productId,
        selectedEngine.bookSummary.bookServiceName,
        selectedEngine.bookSummary.versionId,
        {
          NumberOfTestCases: numberOfTestCases,
          Inputs: inputs,
          TimeZoneOffset: new Date().getTimezoneOffset(),
        }
      )
    );

    setTestCasesProgress(100);
  }, [dispatch, inputs, numberOfTestCases, productId, selectedEngine]);

  const downloadGeneratedTestCases = async () => {
    if (
      generatedTestCasesFile &&
      generatedTestCasesFileName &&
      selectedEngineName
    ) {
      await dispatch(
        EngineActions.downloadGeneratedTestCasesFile(
          productId,
          selectedEngineName,
          generatedTestCasesFile,
          generatedTestCasesFileName
        )
      );
    }
  };

  return {
    submitTestCaseInputs,
    downloadGeneratedTestCases,
    testCasesProgress,
    testGenerateTestParams,
  };
};

export { useTestGenerate };
