import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';
import httpClient from '../helpers/http-client';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const trimSplash = (path: string) => path.replace(/^\/+|\/+$/g, '');

const getFolders = (folderPath: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/GetFolderDocs?folderPath=${folderPath}`;

  return makeRequest<DTO.GetFoldersResponse>('GET', url);
};

const getFiles = (folderPath: string) => {
  const url =
    `${EXCELENGINE_DOMAIN}/api/filemanager/getdocumentlist` +
    `?path=${trimSplash(folderPath)}`;

  return makeRequest<DTO.GetFilesResponse>('POST', url);
};

const uploadFileToFolder = (
  folderPath: string,
  documentId: string | null,
  metadata: object | null,
  file: File,
  privacyLevel: string,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url =
    `${EXCELENGINE_DOMAIN}/api/filemanager/SaveDocument/` +
    `${documentId || folderPath}`;

  const formData = new FormData();

  formData.append('file', file);
  formData.append('privacyLevel', privacyLevel);

  if (metadata != null) {
    formData.append('metadata', JSON.stringify(metadata));
  }

  return uploadRequest('POST', url, formData, onUploadProgress, xhrRef);
};

const updateFile = (documentId: string, file: DTO.EntityDetail) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/UpdateDocument/${documentId}`;

  return makeRequest('POST', url, file);
};

const updateFolder = (folderId: string, folder: DTO.EntityDetail) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/UpdateFolder/${folderId}`;

  return makeRequest('POST', url, folder);
};

const createFolder = (absoluteFolderPath: string, privacyLevel: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/createfolder/${privacyLevel}/${trimSplash(
    absoluteFolderPath
  )}`;

  return makeRequest<DTO.CreateNewFolderResponse>('POST', url);
};

const deleteFolder = (id: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/deletefolder/${id}`;

  return makeRequest('DELETE', url);
};

const deleteFile = (fileID: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/DeleteDocument/${fileID}`;

  return makeRequest('DELETE', url);
};

const getUserGroups = (folderPath: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/getusergroups/${trimSplash(
    folderPath
  )}`;

  return makeRequest<DTO.GetUserGroupsResponse>('GET', url);
};

const getFileDetails = (fileId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/GetDocument/${fileId}`;

  return makeRequest<DTO.GetFileDetailsResponse>('GET', url);
};

const getFolder = (id: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/GetFolder/${id}`;

  return makeRequest<DTO.GetFileDetailsResponse>('GET', url);
};

const uploadProductZipFile = (
  data: {
    file: File;
    path: string;
    refreshToken: string;
  },
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = '/api/filemanager/uploadfolder';

  const formData = new FormData();

  const { file, path, refreshToken } = data;

  formData.append('file', file);
  formData.append('path', path);
  formData.append('refreshToken', refreshToken);

  return uploadRequest<{ data: DTO.UploadProductZipFileResponse }>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const downloadFolder = (folderPath: string, data: DTO.DownloadFolderRequest) =>
  httpClient<{ data: string }>({
    method: 'POST',
    path: `/api/filemanager/downloadfolder/${folderPath}`,
    body: data,
  });

const getDownloadFileUrl = (
  fileId: string,
  fileName: string,
  token: string
) => {
  return `${EXCELENGINE_DOMAIN}/api/filemanager/DownloadDocument/${fileId}/${fileName}?token=${encodeURIComponent(
    token
  )}`;
};

const getDownloadFileByVersionUrl = (
  versionId: string,
  fileName: string,
  token: string
) => {
  return `${EXCELENGINE_DOMAIN}/api/filemanager/DownloadDocumentByVersion/${versionId}/${fileName}?token=${encodeURIComponent(
    token
  )}`;
};

const createLinkResource = (linkResource: DTO.LinkResourceDetail) => {
  const url = `${EXCELENGINE_DOMAIN}/api/filemanager/createlinkresource`;
  return makeRequest<{
    data: string;
  }>('POST', url, linkResource);
};

export const FileManagerService = {
  getFolders,
  getFiles,
  uploadFileToFolder,
  createFolder,
  updateFolder,
  getFileDetails,
  deleteFolder,
  deleteFile,
  updateFile,
  getUserGroups,
  uploadProductZipFile,
  downloadFolder,
  getFolder,
  getDownloadFileUrl,
  createLinkResource,
  getDownloadFileByVersionUrl,
};
