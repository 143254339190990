import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Popover, Button, Icon, Row } from 'antd';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { colorHexToRgba } from '../helpers';
import { BackgroundJobItem } from '../components/BackgroundJobs';
import { ScrollCss } from '../components/Common';
import { useAppDispatch, useModal } from '../hooks';
import { BackgroundJobsActions } from '../actions';

const JobListCss = css`
  .ant-popover.background-jobs {
    padding-top: 0 !important;
    z-index: 999;

    .ant-popover-content > .ant-popover-arrow {
      display: none;
    }

    .ant-popover-content {
      width: 340px;
      max-height: 500px;

      .ant-popover-inner {
        background: ${({ theme }) => theme.color.white};
        border: thin solid
          ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        font-size: 14px;

        .ant-popover-inner-content {
          color: ${({ theme }) => theme.color.dark};
          padding: 10px 20px;
        }
      }
    }
  }
`;

const JobTooltipCss = css`
  .ant-popover.background-jobs-tooltip {
    .ant-popover-content {
      .ant-popover-arrow {
        border-top-color: ${({ theme }) => theme.color.purple} !important;
        border-right-color: ${({ theme }) => theme.color.purple} !important;
      }

      .ant-popover-inner-content > div {
        display: flex;
        max-width: 220px;
        align-items: center;

        .anticon {
          font-size: 21px;
        }

        .content {
          margin-left: 10px;
        }
      }
    }
  }
`;

const PopoverStyle = createGlobalStyle<{ theme: STATES.ThemeState }>`
  ${JobListCss}
  ${JobTooltipCss}
`;

const TogglerContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: 4px;

    .ant-btn {
      border: 2px solid ${({ theme }) => theme.color.dark};
      border-radius: 0;
      font-weight: normal;
      background: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.dark};
      font-size: 12px;
      height: 26px;
      padding: 0 5px 0 0;
      display: flex;
      align-items: center;

      .percent {
        margin-left: 5px;
      }

      .anticon {
        font-size: 12px;
        color: ${({ theme }) => theme.color.dark};
        margin-top: 2px;
      }
    }
  }
`;

const PopoverContent = styled.div`
  .heading {
    margin-bottom: 5px;
  }

  .job-list {
    max-height: 345px;
    overflow-y: auto;
    ${ScrollCss}
  }

  .ant-btn {
    margin-top: 2px;
  }
`;

const backgroundJobSelector = createSelector<
  STATES.AppState,
  boolean,
  STATES.BackgroundJobsState,
  STATES.BackgroundJobsState & { loggedIn: boolean }
>(
  ({ auth }) => auth.loggedIn,
  ({ backgroundJobs }) => backgroundJobs,
  (loggedIn, backgroundJobs) => ({ ...backgroundJobs, loggedIn })
);

const BackgroundJobsPopover: FC = () => {
  const dispatch = useAppDispatch();
  const { showConfirm } = useModal();
  const {
    jobs,
    generalPercent,
    refreshTrigger,
    intervalTime,
    loggedIn,
    currentActive,
    jobsToDownload,
  } = useSelector(backgroundJobSelector);
  const timeoutRef = useRef(0);
  const intervalTimeRef = useRef(intervalTime);
  const [bgListVisible, setBgListVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(
    currentActive !== 'none'
  );

  useEffect(() => {
    const reload = async (updateTimeoutOnly = false) => {
      if (!updateTimeoutOnly) {
        await dispatch(BackgroundJobsActions.getAll());
      }

      if (loggedIn) {
        timeoutRef.current = setTimeout(() => {
          reload();
        }, intervalTime);
      }
    };

    if (loggedIn) {
      reload(intervalTimeRef.current !== intervalTime);
      intervalTimeRef.current = intervalTime;
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [dispatch, refreshTrigger, intervalTime, loggedIn]);

  useEffect(() => {
    const jobsToTriggerDownload = Object.entries(jobsToDownload).reduce<{
      [jobId: string]: string | null;
    }>((prev, [jobId, isWaiting]) => {
      if (isWaiting) {
        const job = jobs.find(
          j =>
            j.id === jobId &&
            j.type === 'DownloadFolder' &&
            j.status === 'COMPLETED' &&
            j.result
        );

        if (job && job.status === 'COMPLETED') {
          prev[jobId] = job.result;
        }
      }

      return prev;
    }, {});

    dispatch(BackgroundJobsActions.triggerDownloadJobs(jobsToTriggerDownload));
  }, [jobsToDownload, jobs, dispatch]);

  useEffect(() => {
    const hasWaitingJobs = Object.keys(jobsToDownload).length !== 0;
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      // Cancel the event
      // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      e.preventDefault();

      // Chrome requires returnValue to be set
      e.returnValue = '';
    };

    hasWaitingJobs && window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [jobsToDownload]);

  useEffect(() => {
    setTooltipVisible(!bgListVisible && currentActive !== 'none');
  }, [currentActive, bgListVisible]);

  const onCancelJob = useCallback(
    (job: DTO.BackgroundJob) => {
      showConfirm({
        content: 'BackgroundJobsPopover.popup.cancel.confirm',
        okText: 'Global.continue',
        onOkAsync: async () => {
          await dispatch(BackgroundJobsActions.cancelJob(job.id));
        },
      });
    },
    [dispatch, showConfirm]
  );

  if (jobs.length === 0) {
    return null;
  }

  const content = (
    <PopoverContent>
      <div className="heading">
        <div>
          <b>
            <FormattedMessage id="BackgroundJobsPopover.popup.title" />
          </b>
        </div>
      </div>
      <div className="job-list">
        {jobs.map(job => (
          <BackgroundJobItem key={job.id} job={job} onCancel={onCancelJob} />
        ))}
      </div>
    </PopoverContent>
  );

  const bgListVisibleOnChange = (next: boolean) => {
    setBgListVisible(next);
  };

  const tooltipVisibleOnChange = () => {
    setTooltipVisible(!bgListVisible && currentActive !== 'none');
  };

  const togglerContainer = (
    <TogglerContainer>
      <Button type="default">
        <span className="percent">{`${generalPercent.toFixed(0)}%`}</span>
        <Icon type={bgListVisible ? 'up' : 'down'} />
      </Button>
    </TogglerContainer>
  );

  const togglerWrapper = (
    <Popover
      overlayClassName="background-jobs-tooltip"
      content={
        <div>
          <div>
            <Icon type="info-circle" theme="outlined" />
          </div>
          <div className="content">
            <FormattedMessage
              id={`BackgroundJobsPopover.tooltip.${currentActive}`}
            />
          </div>
        </div>
      }
      placement="left"
      trigger="hover"
      visible={tooltipVisible}
      onVisibleChange={tooltipVisibleOnChange}
    >
      {togglerContainer}
    </Popover>
  );

  return (
    <>
      <PopoverStyle />
      <Row type="flex" align="middle">
        <Popover
          overlayClassName="background-jobs"
          content={content}
          placement="bottomRight"
          visible={bgListVisible}
          trigger="click"
          onVisibleChange={bgListVisibleOnChange}
        >
          {togglerWrapper}
        </Popover>
      </Row>
    </>
  );
};

export default BackgroundJobsPopover;
