export const ERROR_CODE_INVALID_PRODUCT_NAME = 'INVALID_PRODUCT_NAME';

export const GET_DATADICTIONARY_REQUEST = 'GET_DATADICTIONARY_REQUEST';
export const GET_DATADICTIONARY_SUCCESS = 'GET_DATADICTIONARY_SUCCESS';
export const GET_DATADICTIONARY_FAILURE = 'GET_DATADICTIONARY_FAILURE';

export const DOWNLOAD_DATADICTIONARY_REQUEST =
  'DOWNLOAD_DATADICTIONARY_REQUEST';
export const DOWNLOAD_DATADICTIONARY_SUCCESS =
  'DOWNLOAD_DATADICTIONARY_SUCCESS';
export const DOWNLOAD_DATADICTIONARY_FAILURE =
  'DOWNLOAD_DATADICTIONARY_FAILURE';

export const UPLOAD_DATADICTIONARY = 'UPLOAD_DATADICTIONARY';
export const UPLOAD_DATADICTIONARY_CANCEL = 'UPLOAD_DATADICTIONARY_CANCEL';

export const UPLOAD_DATADICTIONARY_START = 'UPLOAD_DATADICTIONARY_START';
export const UPLOAD_DATADICTIONARY_PROGRESS = 'UPLOAD_DATADICTIONARY_PROGRESS';
export const UPLOAD_DATADICTIONARY_XHR_REF = 'UPLOAD_DATADICTIONARY_XHR_REF';
export const UPLOAD_DATADICTIONARY_SUCCESS = 'UPLOAD_DATADICTIONARY_SUCCESS';
export const UPLOAD_DATADICTIONARY_FAILURE = 'UPLOAD_DATADICTIONARY_FAILURE';
export const UPLOAD_DATADICTIONARY_RESET = 'UPLOAD_DATADICTIONARY_RESET';
