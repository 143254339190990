import httpClient from '../helpers/http-client';

const getByUserId = (userId: string) =>
  httpClient<{ data: DTO.BackgroundJob[] }>({
    method: 'GET',
    path: `/api/backgroundjob/GetUserJobs/${userId}`,
  });

const cancelJob = (jobId: string) =>
  httpClient({
    method: 'POST',
    path: `/api/backgroundjob/CancelJob/${jobId}`,
    body: {},
  });

const getJob = (jobId: string) =>
  httpClient<{ data: DTO.BackgroundJob }>({
    method: 'GET',
    path: `/api/backgroundjob/getJob/${jobId}`,
  });

export const BackgroundJobsService = {
  getByUserId,
  cancelJob,
  getJob,
};
