import { ShareProductConstants, ProductsConstants } from '../constants';
import { SharedProductsAction, ProductsAction } from '../actions/types';
import { arrayReplaceFirst } from '../helpers';

const initialState: STATES.ShareProductState = {
  searchText: '',
  page: 1,
  totalPages: 0,
  pageSize: 15,
  sharedProducts: [],
  isLoading: false,
  filterCategory: undefined,
  filterFavorite: false,
  filterStatus: undefined,
  refreshTrigger: 0,
  sharedFolderId: '',
  sharingProductName: '',
  productShareStatus: null,
};

export const shareProducts = (
  state = initialState,
  action: SharedProductsAction | ProductsAction
): STATES.ShareProductState => {
  switch (action.type) {
    case ShareProductConstants.GET_SHARED_PRODUCTS_REQUEST: {
      const {
        page,
        searchText = '',
        filterCategory,
        filterFavorite,
        filterStatus,
        sort,
        sortBy,
      } = action.payload;

      return {
        ...state,
        isLoading: true,
        page,
        searchText,
        filterCategory,
        filterFavorite: !!filterFavorite,
        filterStatus,
        sort,
        sortBy,
      };
    }
    case ShareProductConstants.GET_SHARED_PRODUCTS_SUCCESS: {
      const {
        products,
        total,
        page,
        searchText = '',
        filterCategory,
        filterFavorite = false,
        filterStatus,
        sortBy,
        sort,
      } = action.payload;

      if (
        searchText !== state.searchText ||
        page !== state.page ||
        filterCategory !== state.filterCategory ||
        filterFavorite !== state.filterFavorite ||
        filterStatus !== state.filterStatus ||
        sort !== state.sort ||
        sortBy !== state.sortBy
      ) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        sharedProducts:
          page === 1 ? products : [...state.sharedProducts, ...products],
        totalPages:
          Math.floor(total / state.pageSize) + (total % state.pageSize ? 1 : 0),
      };
    }

    case ProductsConstants.FAVORITE_PRODUCT_REQUEST: {
      const { productId, favorite } = action.payload;

      return {
        ...state,
        sharedProducts: arrayReplaceFirst(
          state.sharedProducts,
          product => product.id === productId,
          product => ({ ...product, isStarred: favorite })
        ),
      };
    }

    case ShareProductConstants.GET_SHARED_PRODUCTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ProductsConstants.SHARE_PRODUCT_SUCCESS: {
      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case ProductsConstants.GET_PRODUCT_SHARE_STATE_REQUEST: {
      const { productName } = action.payload;

      return {
        ...state,
        sharingProductName: productName,
      };
    }
    case ProductsConstants.GET_PRODUCT_SHARE_STATE_SUCCESS: {
      const { productName, groups } = action.payload;

      return {
        ...state,
        sharingProductName: productName,
        productShareStatus: groups.map(group => ({
          ...group,
          isSharing: false,
        })),
      };
    }
    case ShareProductConstants.PRODUCT_SHARE_STATE_RESET: {
      return {
        ...state,
        sharingProductName: '',
        productShareStatus: null,
      };
    }
    case ShareProductConstants.UPDATE_PRODUCT_SHARE_REQUEST: {
      const { productName, groupName } = action.payload;

      return {
        ...state,
        sharingProductName: productName,
        productShareStatus: arrayReplaceFirst(
          state.productShareStatus || [],
          group => group.groupName === groupName,
          group => ({ ...group, isSharing: true })
        ),
      };
    }
    case ShareProductConstants.UPDATE_PRODUCT_SHARE_SUCCESS: {
      const { productName, groupName, shared } = action.payload;

      return {
        ...state,
        sharingProductName: productName,
        productShareStatus: arrayReplaceFirst(
          state.productShareStatus || [],
          group => group.groupName === groupName,
          group => ({ ...group, isSharing: false, isShared: shared })
        ),
      };
    }
    case ShareProductConstants.UPDATE_PRODUCT_SHARE_FAILURE: {
      const { productName, groupName } = action.payload;

      return {
        ...state,
        sharingProductName: productName,
        productShareStatus: arrayReplaceFirst(
          state.productShareStatus || [],
          group => group.groupName === groupName,
          group => ({ ...group, isSharing: false })
        ),
      };
    }
    default:
      return state;
  }
};
