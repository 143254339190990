import { UserConstants, ProductsBoardConstants } from '../constants';
import { UserAction, ProductsBoardAction } from '../actions/types';
import { arrayRemoveFirst, arrayInsertAt, arrayReplaceFirst } from '../helpers';

export const initialState: STATES.ProductsBoardState = {
  isLoading: false,
  columns: [],
  board: {},
};

const moveColumn = (
  state: STATES.ProductsBoardState,
  fromIndex: number,
  toIndex: number
) => {
  const columns = [...state.columns];
  const [column] = columns.splice(fromIndex, 1);
  columns.splice(toIndex, 0, column);

  return {
    ...state,
    columns,
  };
};

export const moveCardBetweenCols = (
  state: STATES.ProductsBoardState,
  fromCol: string,
  fromIndex: number,
  toCol: string,
  toIndex: number
) => {
  if (fromCol === toCol && state.board[fromCol]) {
    const product = state.board[fromCol].products[fromIndex];
    const column = {
      ...state.board[fromCol],
      products: arrayRemoveFirst(
        state.board[fromCol].products,
        (_, i) => i === fromIndex
      ),
    };

    column.products.splice(toIndex, 0, product);

    return {
      ...state,
      board: {
        ...state.board,
        [fromCol]: column,
      },
    };
  }

  if (fromCol !== toCol && state.board[fromCol] && state.board[toCol]) {
    const product = state.board[fromCol].products[fromIndex];
    const fromColumn = {
      ...state.board[fromCol],
      products: arrayRemoveFirst(
        state.board[fromCol].products,
        (_, i) => i === fromIndex
      ),
    };

    const toColumn = {
      ...state.board[toCol],
      products: arrayInsertAt(state.board[toCol].products, toIndex, product),
    };

    return {
      ...state,
      board: {
        ...state.board,
        [fromCol]: fromColumn,
        [toCol]: toColumn,
      },
    };
  }

  return state;
};

export const productsBoard = (
  state = initialState,
  action: ProductsBoardAction | UserAction
): STATES.ProductsBoardState => {
  switch (action.type) {
    case ProductsBoardConstants.BOARD_EDIT_COLUMN_REQUEST:
    case ProductsBoardConstants.BOARD_GET_STATUS_COLS_REQUEST:
    case ProductsBoardConstants.BOARD_GET_PRODUCTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ProductsBoardConstants.BOARD_GET_STATUS_COLS_FAILURE:
    case ProductsBoardConstants.BOARD_GET_PRODUCTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ProductsBoardConstants.BOARD_GET_STATUS_COLS_SUCCESS: {
      const { statuses } = action.payload;

      return {
        ...state,
        isLoading: false,
        columns: statuses,
      };
    }
    case ProductsBoardConstants.BOARD_GET_PRODUCTS_SUCCESS: {
      const { products } = action.payload;
      const { columns } = state;

      const board = { ...state.board };

      columns.forEach(column => {
        board[column] = {
          ...board[column],
          products: products.filter(product => product.kanbanStatus === column),
        };
      });

      return {
        ...state,
        isLoading: false,
        board,
      };
    }
    case ProductsBoardConstants.BOARD_MOVE_COLUMN_REQUEST: {
      const { fromIndex, toIndex } = action.payload;

      return moveColumn(state, fromIndex, toIndex);
    }
    case ProductsBoardConstants.BOARD_MOVE_COLUMN_FAILURE: {
      const { fromIndex, toIndex } = action.payload;

      return moveColumn(state, toIndex, fromIndex);
    }
    case ProductsBoardConstants.BOARD_MOVE_CARD_BW_COLS_REQUEST: {
      const { fromCol, fromIndex, toCol, toIndex } = action.payload;

      return moveCardBetweenCols(state, fromCol, fromIndex, toCol, toIndex);
    }
    case ProductsBoardConstants.BOARD_MOVE_CARD_BW_COLS_SUCCESS: {
      const { toCol, toIndex } = action.payload;

      return {
        ...state,
        board: {
          ...state.board,
          [toCol]: {
            ...state.board[toCol],
            products: arrayReplaceFirst(
              state.board[toCol].products,
              (_, i) => i === toIndex,
              p => ({
                ...p,
                kanbanStatus: toCol,
              })
            ),
          },
        },
      };
    }
    case ProductsBoardConstants.BOARD_MOVE_CARD_BW_COLS_FAILURE: {
      const { fromCol, fromIndex, toCol, toIndex } = action.payload;

      return moveCardBetweenCols(state, toCol, toIndex, fromCol, fromIndex);
    }
    case ProductsBoardConstants.BOARD_ADD_COLUMN_SUCCESS: {
      const { columnName, columnIndex } = action.payload;

      return {
        ...state,
        columns: arrayInsertAt(state.columns, columnIndex, columnName),
        isLoading: false,
        board: {
          ...state.board,
          [columnName]: {
            products: [],
          },
        },
      };
    }

    case ProductsBoardConstants.BOARD_EDIT_COLUMN_SUCCESS: {
      const {
        columnName,
        columnIndex,
        fromColumnIndex,
        fromColumnName,
      } = action.payload;

      const columns = [...state.columns];
      const { [fromColumnName]: oldCol, ...board } = state.board;
      board[columnName] = oldCol;

      if (columnIndex === fromColumnIndex) {
        columns[columnIndex] = columnName;
      } else {
        columns.splice(fromColumnIndex, 1);
        columns.splice(columnIndex, 0, columnName);
      }

      board[columnName] = {
        ...board[columnName],
        products: board[columnName].products.map(p => ({
          ...p,
          kanbanStatus: columnName,
        })),
      };

      return {
        ...state,
        isLoading: false,
        columns,
        board,
      };
    }

    case ProductsBoardConstants.BOARD_DELETE_COLUMN_SUCCESS: {
      const { columnName } = action.payload;

      if (state.columns.length === 1) {
        return state;
      }

      const colIndex = state.columns.indexOf(columnName);
      const columns = arrayRemoveFirst(state.columns, (_, i) => i === colIndex);
      const resolveColIndex = colIndex === 0 ? 1 : colIndex - 1;
      const resolveColName = state.columns[resolveColIndex];
      const board = { ...state.board };

      board[resolveColName] = {
        ...board[resolveColName],
        products: [
          ...board[resolveColName].products,
          ...board[columnName].products,
        ],
      };

      delete board[columnName];

      return {
        ...state,
        columns,
        board,
      };
    }
    case UserConstants.LOGGED_OUT: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
