import { message as popUpMessage } from 'antd';
import { AlertConstants } from '../constants';
import { AlertThunkAction, AlertAction } from './types';
import { ApiError } from '../helpers';

popUpMessage.config({
  top: 50,
  duration: 2,
  maxCount: 3,
});

const success = (
  content: string,
  values?: { [key: string]: string }
): AlertThunkAction => (dispatch, getState) => {
  const {
    language: { intl },
  } = getState();

  const message = intl.formatMessage(
    {
      id: content,
      defaultMessage: content,
    },
    values
  );

  dispatch({
    type: AlertConstants.SUCCESS,
    payload: { message },
  });

  popUpMessage.success(message);
};

const closeGlobalModal = (): AlertAction => {
  return { type: AlertConstants.ALERT_GLOBAL_CLOSE };
};

const openGlobalAlert = (contentKey: string): AlertAction => ({
  type: AlertConstants.ALERT_GLOBAL_OPEN,
  payload: { contentKey },
});

/**
 * @param content Error code or message
 */
const error = (
  content: string | Error | ApiError
): AlertThunkAction<string> => (dispatch, getState) => {
  const {
    language: { intl },
  } = getState();

  const defaultMessage = intl.formatMessage({
    id: 'DEFAULT_ERROR',
  });

  let messages = [defaultMessage];

  if (typeof content === 'string' && content) {
    messages = [
      intl.formatMessage({
        id: content,
        defaultMessage,
      }),
    ];
  } else if (content instanceof ApiError) {
    messages = [content.message || defaultMessage];
    if (content.codes && content.codes.length > 0) {
      let idx = content.codes.indexOf('RULE_VALIDATION_FAILED');

      if (idx !== -1) {
        content.codes.splice(idx, 1);

        dispatch(openGlobalAlert('Error.permissionError'));
      }

      idx = content.codes.indexOf('NETWORK_CANCELLED');

      if (idx !== -1) {
        content.codes.splice(idx, 1);
      }

      if (content.codes.length === 0) {
        return '';
      }

      messages = content.codes.map(code =>
        intl.formatMessage({
          id: code,
          defaultMessage: content.message || defaultMessage,
        })
      );
    }
  } else if (content instanceof Error) {
    messages = [
      intl.formatMessage({
        id: content.message,
        defaultMessage,
      }),
    ];
  }

  messages.forEach(message => {
    message && popUpMessage.error(message);
  });

  const combinedMessage = messages.join('-');

  return combinedMessage;
};

const clear = (): AlertAction => {
  return { type: AlertConstants.CLEAR };
};

export const AlertActions = {
  success,
  error,
  clear,
  closeGlobalModal,
  openGlobalAlert,
};
