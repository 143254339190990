import React, { NamedExoticComponent, memo } from 'react';
import { Popover as AntdPopover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { createGlobalStyle } from 'styled-components';

const PopoverStyle = createGlobalStyle<{ theme: STATES.ThemeState }>`
  .pf-popover.ant-popover {
    .ant-popover-content > .ant-popover-arrow {
      border-color: #9C43FF !important;
    }
    .ant-popover-inner-content { 
      div {
        color: ${({ theme }) => theme.color.white} !important;
      }
    }
  }
`;

const Popover: NamedExoticComponent<PopoverProps> = memo(
  ({ overlayClassName, ...props }) => {
    return (
      <>
        <PopoverStyle />
        <AntdPopover
          overlayClassName={`pf-popover ${overlayClassName || ''}`}
          {...props}
        />
      </>
    );
  }
);

export { Popover };
