import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'antd';
import { colorHexToRgba } from '../../../helpers';

export interface AddButtonCardProps {
  /**
   * Mouse click event handler
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * Label text
   * @default Not set
   */
  label?: string;
  /**
   * Height of add Button
   */
  height?: number;
}

const StyledAddFolderButton = styled(Button)<{ height?: number }>`
  && {
    height: ${({ height }) => `${height || 150}px`};
    width: 100%;
    border: thin dashed ${({ theme }) => theme.color.gray3};
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      display: block;
      font-size: 16px;
      text-align: center;
      font-weight: normal;
      color: ${({ theme }) => theme.color.gray3};
      margin-top: 30px;
    }

    .anticon {
      color: ${({ theme }) => colorHexToRgba(theme.color.gray3, 0.7)};
      font-size: 40px;
    }
  }
`;

/**
 * Add Button Card component
 * @param param AddButtonCardProps
 */
export const AddButtonCard: FC<AddButtonCardProps> = ({
  onClick,
  label,
  height,
}) => {
  return (
    <StyledAddFolderButton
      height={height}
      onClick={onClick}
      data-cy="add-product-card"
      className="add-button-card"
    >
      <Icon type="plus-circle" />
      <label>
        <FormattedMessage id={label || 'Not set'} />
      </label>
    </StyledAddFolderButton>
  );
};

export default AddButtonCard;
