import React, { FC, ReactNode, useCallback, useContext } from 'react';
import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/es/modal';
import { ModalFunc } from 'antd/es/modal/Modal';
import {
  FormattedHTMLMessage,
  IntlShape,
  RawIntlProvider,
  useIntl,
} from 'react-intl';
import styled, { ThemeContext, ThemeProvider } from 'styled-components';
import { IconSvg, LoadingIndicator } from '../components/Common';

const { confirm, info } = Modal;

interface ModalConfig extends ModalFuncProps {
  iconType?: string;
  titleValues?: Record<string, PrimitiveType>;
  contentValues?: Record<string, PrimitiveType>;
  contentFontSize?: 'normal' | 'smaller';
  onOkAsync?: () => Promise<void>;
  showIcon?: boolean;
  iconColor?: 'dangerColor' | 'primaryColor' | 'secondaryColor';
}

const ConfirmTitle = styled.div<{
  contentFontSize?: ModalConfig['contentFontSize'];
  iconColor?: 'dangerColor' | 'primaryColor' | 'secondaryColor';
}>`
  font-size: 28px;
  font-weight: normal;
  text-align: center;
  color: ${({ theme }) => theme.color.dark};
  font-size: ${({ contentFontSize = 'normal' }) =>
    contentFontSize === 'normal' ? '28px' : '24px'};

  .anticon {
    color: ${({ theme, iconColor }) =>
      iconColor ? `${theme.vars[iconColor]}` : theme.vars.dangerColor};
    font-size: 50px;
    display: block;
  }
`;

const ConfirmContent = styled.div<{
  contentFontSize: ModalConfig['contentFontSize'];
  iconColor?: 'dangerColor' | 'primaryColor' | 'secondaryColor';
}>`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ contentFontSize = 'normal' }) =>
    contentFontSize === 'normal' ? '24px' : '18px'};
`;

interface AppContextProps {
  intl: IntlShape;
  themeContext: {};
}

const AppContext: FC<AppContextProps> = ({ intl, themeContext, children }) => {
  return (
    <RawIntlProvider value={intl}>
      <ThemeProvider theme={themeContext}>{children}</ThemeProvider>
    </RawIntlProvider>
  );
};

interface ConfirmContentTitleProps extends AppContextProps {
  iconType?: string;
  content?: ReactNode;
  contentValues: Record<string, PrimitiveType> | undefined;
  type: 'title' | 'content';
  contentFontSize?: ModalConfig['contentFontSize'];
  loading?: boolean;
  showIcon?: boolean;
  iconColor?: 'dangerColor' | 'primaryColor' | 'secondaryColor';
}

const destroyModalCallback = (
  modal: ReturnType<ModalFunc>,
  before?: () => void
) => {
  before && before();

  modal.destroy();
};

const ConfirmContentTitle: FC<ConfirmContentTitleProps> = ({
  intl,
  themeContext,
  iconType,
  content,
  contentValues,
  type = 'title',
  contentFontSize,
  loading = false,
  showIcon = true,
  iconColor,
}) => {
  const Component = type === 'title' ? ConfirmTitle : ConfirmContent;
  return (
    <AppContext intl={intl} themeContext={themeContext}>
      <Component iconColor={iconColor} contentFontSize={contentFontSize}>
        {type === 'title' && showIcon === true && (
          <IconSvg type={iconType || 'warning'} />
        )}
        {content &&
          (typeof content === 'string' ? (
            <FormattedHTMLMessage id={content} values={contentValues} />
          ) : (
            content
          ))}
      </Component>
      {loading && <LoadingIndicator spinning>&nbsp;</LoadingIndicator>}
    </AppContext>
  );
};

const resolveButtonText = (
  text: string | ReactNode | null | undefined,
  intl: IntlShape,
  defaultText: string
) => {
  if (text == null || typeof text === 'string') {
    return intl.formatMessage({ id: text || defaultText });
  }

  return text;
};

const useModal = () => {
  const intl = useIntl();
  const themeContext = useContext(ThemeContext);

  const showConfirm = useCallback(
    ({
      iconType,
      title,
      titleValues,
      okText,
      cancelText,
      width,
      content,
      contentValues,
      centered,
      showIcon,
      iconColor,
      onOkAsync,
      onOk,
      onCancel,
      contentFontSize = 'normal',
      ...rest
    }: ModalConfig) => {
      const modal = confirm({
        ...rest,
        icon: null,
        centered: typeof centered === 'boolean' ? centered : true,
        width: width || 700,
        title: (
          <ConfirmContentTitle
            intl={intl}
            themeContext={themeContext}
            iconType={iconType || 'warning'}
            contentValues={titleValues}
            content={title}
            type="title"
            iconColor={iconColor}
            showIcon={showIcon}
          />
        ),
        content: content && (
          <ConfirmContentTitle
            intl={intl}
            themeContext={themeContext}
            type="content"
            contentValues={contentValues}
            content={content}
            contentFontSize={contentFontSize}
          />
        ),
        okText: resolveButtonText(okText, intl, 'Global.confirm'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        cancelText: resolveButtonText(cancelText, intl, 'Global.cancel'),
        cancelButtonProps: {
          type: 'default',
          shape: 'round',
        },
        onCancel: () => destroyModalCallback(modal, onCancel),
        onOk:
          typeof onOkAsync === 'function'
            ? async () => {
                modal.update({
                  content: (
                    <ConfirmContentTitle
                      intl={intl}
                      themeContext={themeContext}
                      type="content"
                      contentValues={contentValues}
                      content={content}
                      contentFontSize={contentFontSize}
                      loading
                    />
                  ),
                });

                await onOkAsync();

                modal.destroy();
              }
            : () => destroyModalCallback(modal, onOk),
      });

      return modal;
    },
    [intl, themeContext]
  );

  const showInfo = useCallback(
    ({
      iconType,
      title,
      titleValues,
      okText,
      width,
      content,
      contentValues,
      centered,
      onOk,
      showIcon,
      iconColor,
      contentFontSize = 'normal',
      ...rest
    }: ModalConfig) => {
      const modal = info({
        ...rest,
        icon: null,
        centered: typeof centered === 'boolean' ? centered : true,
        width: width || 700,
        title: (
          <ConfirmContentTitle
            intl={intl}
            themeContext={themeContext}
            iconType={iconType || 'exclamation-circle'}
            contentValues={titleValues}
            content={title}
            type="title"
            showIcon={showIcon}
            iconColor={iconColor}
          />
        ),
        content: content && (
          <ConfirmContentTitle
            intl={intl}
            themeContext={themeContext}
            type="content"
            contentValues={contentValues}
            content={content}
            contentFontSize={contentFontSize}
          />
        ),
        okText: resolveButtonText(okText, intl, 'Global.ok'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onOk: () => destroyModalCallback(modal, onOk),
      });

      return modal;
    },
    [intl, themeContext]
  );

  return { showConfirm, showInfo };
};

export { useModal };
