import { DmlFileConstants } from '../constants';
import { DmlFileService } from '../services/dmlfile.service';
import { DmlFileThunkAction, DmlFileThunkActionSync } from './types';
import { ApiError } from '../helpers';
import { AlertActions } from './alert.actions';
import { BackgroundJobsService } from '../services';

const runutility = (
  product: string,
  engine: string,
  version: string,
  smartLevel: string
): DmlFileThunkAction => async dispatch => {
  try {
    dispatch({
      type: DmlFileConstants.RUN_DML_UTILITY_REQUEST,
    });

    const { payload, status } = await DmlFileService.runutility(
      product,
      engine,
      version,
      smartLevel
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: DmlFileConstants.RUN_DML_UTILITY_SUCCESS,
      payload: {
        queueId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: DmlFileConstants.RUN_DML_UTILITY_FAILURE,
      payload: { error: msg },
    });
  }
};

const clearJob = (): DmlFileThunkAction => dispatch => {
  dispatch({
    type: DmlFileConstants.CLEAR_DML_JOB,
  });
};

const getRunStatus = (
  queueId: string
): DmlFileThunkAction => async dispatch => {
  try {
    dispatch({
      type: DmlFileConstants.GET_RUN_STATUS_REQUEST,
    });

    const { payload } = await BackgroundJobsService.getJob(queueId);

    if (payload.status === 'Error' || payload.data.status === 'FAILED') {
      if (payload.data.data.output) {
        // in case of error as well if utility generated output, it should we shown on UI
        dispatch({
          type: DmlFileConstants.GET_RUN_STATUS_SUCCESS,
          payload: {
            job: payload.data,
          },
        });
      }
      if (payload.data.result) {
        payload.errorCode = payload.data.result;
        throw new ApiError(payload);
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: DmlFileConstants.GET_RUN_STATUS_SUCCESS,
      payload: {
        job: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: DmlFileConstants.GET_RUN_STATUS_FAILURE,
      payload: { error: msg },
    });
  }
};

const downloadDmlFile = (dmlFileName: string): DmlFileThunkActionSync => (
  _,
  getState
) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) {
    return;
  }

  const { id_token } = userAuth;

  const downloadUrl = DmlFileService.getDownloadDmlFileUrl(
    dmlFileName,
    id_token
  );

  window.location.href = downloadUrl;
};

const openDmlLog = (dmlFileName: string): DmlFileThunkActionSync => (
  _,
  getState
) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) {
    return;
  }

  const { id_token } = userAuth;

  const downloadUrl = DmlFileService.getDownloadDmlFileUrl(
    dmlFileName,
    id_token
  );

  const win = window.open(downloadUrl, '_blank');
  win && win.focus();
};

export const DmlFileActions = {
  runutility,
  downloadDmlFile,
  getRunStatus,
  clearJob,
  openDmlLog,
};
