import { createIntl, createIntlCache } from 'react-intl';

const cache = createIntlCache();

const createNewIntl = (language: string, messages: { [key: string]: string }) =>
  createIntl(
    {
      locale: language,
      messages,
      onError: () => {},
    },
    cache
  );

export { createNewIntl };
