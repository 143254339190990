import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon, Layout } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CollapsibleSearchInput } from '../Common';

const { Header } = Layout;

const StyledBackLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 28px;
  color: ${({ theme }) => theme.vars.textColor};

  .anticon {
    font-size: 24px;
    margin-right: 10px;
  }
`;

const StyledHeader = styled(Header)`
  && {
    padding: 0;
    display: flex;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;

    @media screen and (min-width: 960px) {
      margin: 0;
    }
  }
`;

interface BackAndSearchHeaderProps {
  backLinkText: string;
  searchPlaceHolder: string;
  onSearchTermChange?: (text: string) => void;
  onSearchTermSubmit?: () => void;
  searchTerm?: string;
}

const BackAndSearchHeader: FC<BackAndSearchHeaderProps> = ({
  backLinkText,
  searchPlaceHolder,
  onSearchTermChange,
  searchTerm,
  onSearchTermSubmit,
}) => {
  return (
    <StyledHeader>
      <StyledBackLink to="/">
        <Icon type="left" />
        <span>
          <FormattedMessage id={backLinkText} />
        </span>
      </StyledBackLink>
      {!!onSearchTermChange && (
        <CollapsibleSearchInput
          placeholder={searchPlaceHolder}
          onChange={onSearchTermChange}
          onSubmit={onSearchTermSubmit}
          value={searchTerm}
        />
      )}
    </StyledHeader>
  );
};

export { BackAndSearchHeader };
