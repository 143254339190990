import React, { useCallback, useState } from 'react';
import { startsWith } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Button, Menu, Drawer, Row, Col } from 'antd';
import styled from 'styled-components';
import { useParams, useLocation, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Logo, IconSvg } from '../Common';
import { LanguageDropdown } from '../Language';
import { colorHexToRgba, getInitials } from '../../helpers';
import { UserActions, ShareProductActions } from '../../actions';
import AboutModal from '../../containers/AboutModal';
import { useModal } from '../../hooks';
import BackgroundJobsPopover from '../../containers/BackgroundJobsPopover';
import { SystemConstants } from '../../constants';

const { Header } = Layout;

interface RouteParams {
  productId: string;
}

const mapStateToProps = ({
  user: { userInfo },
  auth: { isAdmin, isSuperuser, tenant, issuerConfig },
}: STATES.AppState) => ({
  displayName: (userInfo && userInfo.displayName) || '...',
  userEmailID: userInfo && userInfo.userEmailID,
  isAdmin,
  isSuperuser,
  tenant,
  issuerConfig,
});

type MainHeaderProps = { sideMenuVisible?: boolean };

const StyledHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 5;
  height: 86px !important;
  padding: 0 0px !important;

  .header-wrapper {
    padding: 0px 40px !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  height: 86px !important;
  background-color: ${({ theme }) => theme.color.white};
  padding-top: 30px;
`;

const StyledMenuToggler = styled(Button)`
  && {
    border: none !important;
    margin-left: 30px;
    width: 40px;
    color: ${({ theme }) => theme.vars.priminparyColor} !important;
    font-size: 20px;
    margin: 0 5px;
    padding-top: 9px;

    .anticon {
      font-size: 28px;
      color: ${({ theme }) => theme.vars.primaryColor};
    }

    :hover,
    :active,
    :focus {
      .anticon {
        color: ${({ theme }) => theme.color.dark};
      }
    }
  }
`;

const StyledMenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-mask {
    background-color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
  }
  .ant-drawer-content-wrapper {
    width: 400px !important;
    height: auto !important;
    .ant-drawer-header {
      background: ${({ theme }) => theme.color.gray6};
      padding: 50px 30px;
    }
    .ant-drawer-body {
      padding: 0;
      .ant-menu-item-divider {
        margin: 0;
      }
      .ant-menu-item {
        margin: 0;
        padding: 0;
        height: 50px;
        font-size: 20px;
        font-weight: bold !important;

        b {
          color: ${({ theme }) => theme.color.gray1};
        }

        a {
          line-height: 2.5;
          padding: 0 43px 0px 43px;

          .anticon {
            margin-right: 30px;
            font-size: 30px;
            opacity: 1;
            position: relative;
            top: 4px;
          }
        }
      }

      .ant-menu li :hover {
        background-color: ${({ theme }) =>
          colorHexToRgba(theme.color.aqua, 0.5)};
        color: ${({ theme }) => theme.color.dark};

        .anticon {
          color: ${({ theme }) => theme.color.dark};
          background-color: ${({ theme }) =>
            colorHexToRgba(theme.color.aqua, 0)};
        }
        .anticon svg {
          background-color: ${({ theme }) =>
            colorHexToRgba(theme.color.aqua, 0)};
        }
      }
      .ant-menu-item-selected > a {
        color: ${({ theme }) => theme.color.aqua};
      }
    }
  }
`;

const HeaderContainer = styled.div``;

const StyledProfileIcon = styled.div`
  display: inline-block;
  align-items: center;
  padding: 0px 10px;

  .initials {
    width: 36px;
    cursor: pointer;
    height: 36px;
    background-color: ${({ theme }) => theme.color.aqua};
    border-radius: 18px;
    margin-right: 0px;
    color: ${({ theme }) => theme.vars.white};
    display: inline-block;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 36px;
  }

  .name {
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
    height: 36px;

    b {
      line-height: 2.25;
    }
  }
`;

const StyledProfileInfo = styled.div`
  align-items: center;
  padding: 0px;

  .initials {
    width: 60px;
    cursor: pointer;
    height: 60px;
    background-color: ${({ theme }) => theme.vars.secondaryColor};
    border-radius: 30px;
    margin-right: 0px;
    color: ${({ theme }) => theme.vars.white};
    padding: 0px;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 60px;
    display: inline-block;
    margin-top: 20%;
  }

  .name {
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
    height: 35px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    b {
      line-height: 2.25;
    }
  }

  .emailAddress {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    overflow: hidden;
  }

  .tenant {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 300;
    overflow: hidden;
  }
`;

const KEYCLOAK_DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT;

const MainHeader: React.FC<MainHeaderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [aboutModdalVisible, setAboutModalVisible] = useState(false);
  const { showConfirm } = useModal();
  const { productId } = useParams<RouteParams>();
  const location = useLocation();
  const { displayName, userEmailID, issuerConfig } = useSelector(
    mapStateToProps
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const tenant = localStorage.getItem('Tenant') || KEYCLOAK_DEFAULT_TENANT;

  const onLogOut: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();

    showConfirm({
      content: 'MainHeader.logout.confirm',
      okText: 'Global.yes',
      cancelText: 'Global.no',
      onOk: () => {
        dispatch(UserActions.logout(issuerConfig));
      },
    });
  };

  const showAboutModal: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();

    setAboutModalVisible(true);
  };

  const hideAboutModal = useCallback(() => {
    setAboutModalVisible(false);
  }, []);

  const onShare: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    e => {
      e.preventDefault();

      dispatch(ShareProductActions.getProductShareState(productId));
    },
    [productId, dispatch]
  );

  const onClose = () => {
    setShowDrawer(false);
  };

  const onOpen = () => {
    setShowDrawer(true);
  };

  const userMenu = (
    <Menu onClick={onClose}>
      {startsWith(location.pathname, '/admin') && (
        <Menu.Item>
          <Link to="/">
            <IconSvg type="DoubleLeftArrow" />
            <b>
              <FormattedMessage id="MainHeader.menu.catalogue" />
            </b>
          </Link>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item>
        <Link to="/admin/options" data-testid="options-menu-item">
          <IconSvg type="setting" />
          <b>
            <FormattedMessage id="MainHeader.menu.options" />
          </b>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/" onClick={showAboutModal} data-testid="about-menu-item">
          <IconSvg type="info-circle" />
          <FormattedMessage id="MainHeader.menu.about" />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a
          target="_blank"
          href={SystemConstants.EXTERNAL_SUPPORT_LINK}
          rel="noopener noreferrer"
        >
          <IconSvg type="question-circle" />
          <FormattedMessage id="MainHeader.menu.support" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/" onClick={onLogOut} data-testid="logout-menu-item">
          <IconSvg type="Logout" />
          <b>
            <FormattedMessage id="MainHeader.menu.logout" />
          </b>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <HeaderContainer>
      <StyledHeader className="main-header">
        <Wrapper className="header-wrapper">
          {children || <Logo type="OnWhite" height="50px" />}

          <StyledMenuContainer className="header-menu">
            <BackgroundJobsPopover />
            {productId && !location.pathname.startsWith('/share') && (
              <StyledMenuToggler
                shape="circle"
                icon="share-alt"
                onClick={onShare}
                data-testid="share-product-btn"
              />
            )}
            <LanguageDropdown />
            <StyledProfileIcon onClick={onOpen} data-testid="user-menu-toggler">
              <b className="initials">{getInitials(displayName)}</b>
            </StyledProfileIcon>
          </StyledMenuContainer>
          <StyledDrawer
            title={
              <StyledProfileInfo>
                <Row gutter={0}>
                  <Col span={5}>
                    <b className="initials">{getInitials(displayName)}</b>
                  </Col>
                  <Col span={19}>
                    <div className="name">
                      <b>{displayName}</b>
                    </div>
                    <div className="emailAddress">{userEmailID}</div>
                    <div className="tenant">{tenant}</div>
                  </Col>
                </Row>
              </StyledProfileInfo>
            }
            placement="right"
            closable={false}
            onClose={onClose}
            visible={showDrawer}
          >
            {userMenu}
          </StyledDrawer>
        </Wrapper>
      </StyledHeader>
      <AboutModal visible={aboutModdalVisible} onClose={hideAboutModal} />
    </HeaderContainer>
  );
};

export default MainHeader;
