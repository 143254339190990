import { message } from 'antd';

const copyToClipboard = (content: string, msg?: string) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    window.clipboardData.setData('Text', content);
    msg && message.success(msg);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const fakeElem = document.createElement('textarea');
    // Prevent zooming on iOS
    fakeElem.style.fontSize = '12pt';
    // Reset box model
    fakeElem.style.border = '0';
    fakeElem.style.padding = '0';
    fakeElem.style.margin = '0';
    // Move element out of screen horizontally
    fakeElem.style.position = 'absolute';
    fakeElem.style.left = '-9999px';
    // Move element to the same position vertically
    const yPosition = window.pageYOffset || document.documentElement.scrollTop;
    fakeElem.style.top = `${yPosition}px`;

    fakeElem.setAttribute('readonly', 'readonly');
    fakeElem.value = content;

    document.body.appendChild(fakeElem);

    fakeElem.select();
    fakeElem.setSelectionRange(0, fakeElem.value.length);

    try {
      document.execCommand('copy');
      msg && message.success(msg);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }
};

export { copyToClipboard };
