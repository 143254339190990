import { FormBuilderConstants } from '../constants';
import { FormBuilderAction } from '../actions/types';

export const initialState: STATES.FormBuilderState = {
  isExecuting: false,
  documentVersion: '',
  formspec: null,
  formstate: null,
  fields: {},
};

export const formBuilder = (
  state = initialState,
  action: FormBuilderAction
): STATES.FormBuilderState => {
  switch (action.type) {
    case FormBuilderConstants.GET_FORMSTATE_REQUEST:
    case FormBuilderConstants.GET_FORMSPEC_REQUEST: {
      return {
        ...state,
        isExecuting: true,
      };
    }
    case FormBuilderConstants.GET_FORMSPEC_SUCCESS: {
      const { formspec, documentVersion } = action.payload;

      return {
        ...state,
        documentVersion,
        formspec,
        isExecuting: false,
      };
    }
    case FormBuilderConstants.GET_FORMSTATE_SUCCESS: {
      const { formstate } = action.payload;

      let fields = state.fields;

      if (Object.keys(state.fields).length === 0 && state.formspec) {
        const fieldsFromSepec = state.formspec.sections.reduce(
          (prev, current) => {
            const fieldsInSection = current.subSections
              ? current.subSections.reduce((subPrev, subCurrent) => {
                  subPrev.push(
                    ...subCurrent.fields.filter(field => !!field.jsonPath)
                  );

                  return subPrev;
                }, [] as DTO.FormSpecField[])
              : [];

            prev.push(...fieldsInSection);

            return prev;
          },
          [] as DTO.FormSpecField[]
        );

        fields = fieldsFromSepec.reduce((prev, { name, jsonPath }) => {
          prev[jsonPath] =
            formstate.fields[name] && formstate.fields[name].defaultValue
              ? formstate.fields[name].defaultValue.toString()
              : '';

          return prev;
        }, {} as { [name: string]: string });
      }

      return {
        ...state,
        fields,
        formstate,
        isExecuting: false,
      };
    }
    case FormBuilderConstants.RESET_FORMBUILDER: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
