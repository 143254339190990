import makeRequest from '../helpers/make-request';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const runutility = (
  product: string,
  engine: string,
  version: string,
  smartLevel: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/dml/${product}/${engine}/${version}`;
  const formData = new FormData();
  smartLevel && formData.append('smartLevel', smartLevel);
  return makeRequest<{ data: string }>('POST', url, formData);
};

const getDownloadDmlFileUrl = (dmlFileName: string, idToken: string) => {
  return `${EXCELENGINE_DOMAIN}/api/v1/dml/download/${encodeURIComponent(
    dmlFileName
  )}?token=${encodeURIComponent(idToken)}`;
};

export const DmlFileService = {
  runutility,
  getDownloadDmlFileUrl,
};
