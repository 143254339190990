import React, {
  NamedExoticComponent,
  memo,
  useCallback,
  ChangeEventHandler,
  RefObject,
} from 'react';
import { Input, Icon } from 'antd';
import { InputProps } from 'antd/lib/input';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { colorHexToRgba } from '../../../helpers';

interface SearchInputProps extends Omit<InputProps, 'onChange'> {
  searchInputRef?: RefObject<Input>;
  onChange?: (value: string) => void;
}

const StyledInput = styled(Input)`
  && {
    width: 300px;
    margin-right: 10px;

    .ant-input {
      border: 1px solid ${({ theme }) => theme.color.aqua};
    }

    &.ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding-right: 50px;
    }

    .anticon {
      font-size: 30px;
      color: ${({ theme }) => colorHexToRgba(theme.color.aqua, 0.4)};
    }
  }
`;

const SearchInput: NamedExoticComponent<SearchInputProps> = memo(
  ({ suffix, onChange, value, placeholder, searchInputRef, ...rest }) => {
    const intl = useIntl();

    const onClear = useCallback(() => {
      onChange && onChange('');
      searchInputRef &&
        searchInputRef.current &&
        searchInputRef.current.focus();
    }, [onChange, searchInputRef]);

    const onInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      e => {
        onChange && onChange(e.target.value);
      },
      [onChange]
    );

    return (
      <StyledInput
        {...rest}
        ref={searchInputRef}
        placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
        onChange={onInputChange}
        value={value}
        data-testid="search-files-folders"
        allowClear={false}
        suffix={
          value ? (
            <Icon type="close-circle" onClick={onClear} />
          ) : (
            <Icon type="search" />
          )
        }
      />
    );
  }
);

export { SearchInput };
