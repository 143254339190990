import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

interface CustomSwitchProps extends SwitchProps {
  falseLabel?: string;
  trueLabel?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled.span<{ hightlight: boolean }>`
  margin: 0 10px;
  font-weight: ${({ hightlight }) => (hightlight ? 'bold' : 'normal')};
`;

const CustomSwitch: FC<CustomSwitchProps> = ({
  falseLabel,
  trueLabel,
  checked,
  className,
  ...rest
}) => {
  return (
    <Container className={className}>
      {falseLabel && (
        <SwitchLabel hightlight={!checked}>
          <FormattedMessage id={falseLabel} />
        </SwitchLabel>
      )}
      <Switch {...rest} checked={checked} />
      {trueLabel && (
        <SwitchLabel hightlight={!!checked}>
          <FormattedMessage id={trueLabel} />
        </SwitchLabel>
      )}
    </Container>
  );
};

export { CustomSwitch };
