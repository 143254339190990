import {
  PermissionConstants,
  UserConstants,
  ProductsConstants,
  EngineConstants,
} from '../constants';
import {
  PermissionsAction,
  UserAction,
  ProductsAction,
  EngineAction,
} from '../actions/types';

export const initialPermissionsState: STATES.PermissionsState = {
  userGroupRules: null,
  isLoadingRules: false,
  noOfEnginesOfProduct: 0,
  noOfProducts: 0,
  productId: null,
  maxOfEnginesRule: Number.MAX_SAFE_INTEGER,
  maxOfProductsRule: Number.MAX_SAFE_INTEGER,
};

const getMaxNumberRule = (
  userGroupRules: {
    [groups: string]: DTO.UserGroupRule;
  },
  actionPermission: DTO.ActionPermission
) => {
  const { moduleName, ruleName } = actionPermission;

  if (Object.values(userGroupRules).length === 0) {
    return Number.MAX_SAFE_INTEGER;
  }

  return Math.max(
    ...Object.values(userGroupRules).map(userGroupRule => {
      const moduleRule = userGroupRule.moduleRules.find(
        rule => rule.moduleName === moduleName
      );

      if (moduleRule) {
        const actionRule = moduleRule.rules.find(
          rule => rule.ruleName === ruleName
        );

        if (actionRule) {
          return actionRule.ruleValue as number;
        }
      }

      return Number.MAX_SAFE_INTEGER;
    })
  );
};

export const permissionsReducer = (
  state = initialPermissionsState,
  action: PermissionsAction | UserAction | ProductsAction | EngineAction
): STATES.PermissionsState => {
  switch (action.type) {
    case PermissionConstants.LOAD_USER_GROUP_RULES_REQUEST: {
      return {
        ...state,
        isLoadingRules: true,
      };
    }
    case PermissionConstants.LOAD_USER_GROUP_RULES_SUCCESS: {
      const { userGroupRules } = action.payload;

      const maxOfProductsRule = getMaxNumberRule(
        userGroupRules,
        PermissionConstants.PRODUCT_MAX
      );
      const maxOfEnginesRule = getMaxNumberRule(
        userGroupRules,
        PermissionConstants.ENGINE_MAX
      );

      return {
        ...state,
        isLoadingRules: false,
        userGroupRules,
        maxOfProductsRule,
        maxOfEnginesRule,
      };
    }
    case PermissionConstants.LOAD_USER_GROUP_RULES_FAILURE: {
      return {
        ...state,
        isLoadingRules: false,
        userGroupRules: {},
      };
    }
    case ProductsConstants.GET_PRODUCTS_SUCCESS: {
      const { total, searchText } = action.payload;

      if (searchText) {
        return state;
      }

      return {
        ...state,
        noOfProducts: total,
      };
    }
    case ProductsConstants.GET_PRODUCT_DETAIL_SUCCESS: {
      const { product, productName } = action.payload;

      return {
        ...state,
        productId: productName,
        noOfEnginesOfProduct: product.calculationEngines?.count || 0,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINES_SUCCESS: {
      const { total, productName, searchText } = action.payload;

      if (searchText) {
        return state;
      }

      return {
        ...state,
        productId: productName,
        noOfEnginesOfProduct: total,
      };
    }
    case UserConstants.LOGGED_OUT: {
      return { ...initialPermissionsState };
    }
    default:
      return state;
  }
};
