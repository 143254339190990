import jwt from 'jsonwebtoken';
import { store } from './store';
import { ApiError } from './errors';
import { UserConstants } from '../constants';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;
const KEYCLOAK_DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT;
const APIGATEWAY_API_VERSION = process.env.REACT_APP_APIGATEWAY_API_VERSION;
const APIGATEWAY_SUBSCRIPTION_KEY =
  process.env.REACT_APP_APIGATEWAY_SUBSCRIPTION_KEY;

const getXHRResponse = <T = {}>(xhr: XMLHttpRequest) => {
  const { status, responseText, response } = xhr;

  const text = responseText || response;
  let payload: T = text;

  if (text) {
    try {
      payload = JSON.parse(text);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return {
    status,
    payload,
    headers: new Headers(),
  };
};

const uploadRequest = <T = {}>(
  method: string,
  url: string,
  body: FormData,
  onUploadProgress?: (uploadPercent: number) => void,
  xhrRef?: (xhr: XMLHttpRequest) => void
): Promise<DTO.ApiResponse<T>> => {
  const { userAuth } = store.getState().auth;
  const id_token = userAuth && userAuth.id_token;
  const decodedJwt = id_token && jwt.decode(id_token);
  const headerApiGatewayApiVersion = {
    apiVersion: APIGATEWAY_API_VERSION || '',
  };

  const headerApiGatewaySubscriptionKey = {
    'ocp-apim-subscription-key': APIGATEWAY_SUBSCRIPTION_KEY || '',
  };

  const uploadTask = (userToken: string) =>
    new Promise<DTO.ApiResponse<T>>(resolve => {
      const xhr = new XMLHttpRequest();

      if (onUploadProgress && xhr.upload) {
        xhr.upload.onprogress = e =>
          onUploadProgress(e.total > 0 ? (e.loaded / e.total) * 100 : 0);
      }

      xhr.onerror = () => {
        resolve(getXHRResponse<T>(xhr));
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          resolve(getXHRResponse<T>(xhr));

          return;
        }

        resolve(getXHRResponse<T>(xhr));
      };

      xhr.open(method, url);

      const tenant = localStorage.getItem('Tenant');
      const headerTenant = {
        'x-tenant-name': tenant || KEYCLOAK_DEFAULT_TENANT || '',
      };
      const headerAuth = {
        Authorization: `Bearer ${userToken}`,
      };

      Object.entries({
        ...headerAuth,
        ...headerTenant,
        ...headerApiGatewayApiVersion,
        ...headerApiGatewaySubscriptionKey,
      }).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });

      xhr.send(body);

      xhrRef && xhrRef(xhr);
    });

  if (
    !id_token ||
    (decodedJwt && decodedJwt['exp'] && decodedJwt['exp'] * 1000 < Date.now())
  ) {
    const refresh_token = userAuth && userAuth.refresh_token;

    const refreshTokenTask = async (): Promise<string> => {
      let result: Response;
      let newIdToken: string;
      if (refresh_token) {
        const tenant = localStorage.getItem('Tenant');
        const headerTenant = {
          'x-tenant-name': tenant || KEYCLOAK_DEFAULT_TENANT || '',
        };
        const refreshRequestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...headerTenant,
            ...headerApiGatewayApiVersion,
            ...headerApiGatewaySubscriptionKey,
          },
          body: JSON.stringify({ refresh_token }),
        };
        result = await fetch(
          `${EXCELENGINE_DOMAIN}/api/v1/userlogin/refreshtoken`,
          refreshRequestOptions
        );
        const payload = await result.json();
        const { data } = payload;
        newIdToken = data.id_token;

        if (!newIdToken) {
          store.dispatch({ type: UserConstants.LOGOUT });

          throw new ApiError({
            error_code: UserConstants.REFRESH_TOKEN_LIMIT_REACHED,
          });
        }

        store.dispatch({
          type: UserConstants.SET_ID_TOKEN,
          payload: {
            id_token: newIdToken,
          },
        });

        return newIdToken;
      }
      return '';
    };

    return refreshTokenTask().then(uploadTask);
  }

  return uploadTask(id_token);
};

export default uploadRequest;
