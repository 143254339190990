import jwt from 'jsonwebtoken';
import { store } from './store';
import { UserConstants } from '../constants';
import { ApiError } from './errors';
import fetchNetworkErrorWrap from './fetchNetworkErrorWrap';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;
const KEYCLOAK_DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT;
const APIGATEWAY_API_VERSION = process.env.REACT_APP_APIGATEWAY_API_VERSION;
const APIGATEWAY_SUBSCRIPTION_KEY =
  process.env.REACT_APP_APIGATEWAY_SUBSCRIPTION_KEY;

interface ErrorConfig<T = {}> {
  isErrorStatus: (status: number) => boolean;
  isPayloadStatusError: (payload: DTO.ApiResponse<T>['payload']) => boolean;
  /**
   * By default it's True, throw an ApiError if response doesn't match isErrorStatus & isPayloadStatusError
   */
  throwAPIError: boolean;
  onError: (status: number, payload: DTO.ApiResponse<T>['payload']) => void;
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface HttpClientOptions<T = {}> {
  method: HttpMethod;
  /**
   * By default, it's EXCELENGINE_DOMAIN
   */
  domain?: string;
  path: string;
  // Accept all types for body
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  contentType?: string;
  query?;
  options?: RequestInit;
  /**
   * Set this to True if you want to return blob type instead of json data
   */
  returnBlob?: boolean;
  errorConfig?: Partial<ErrorConfig<T>>;
}

const defaultErrorConfig: ErrorConfig = {
  isErrorStatus: status => status !== 200,
  isPayloadStatusError: payload =>
    !['Success', 'OK', 'Ok'].includes(payload.status || ''),
  throwAPIError: true,
  onError: () => {},
};
/**
 * New http request wrapper, by default it throws ApiError when get an error (status !== 200 or payload.status is not Success/OK/Ok)
 * but it's configurable
 * @param param0
 */
const httpClient = async <T = {}>({
  method,
  domain = EXCELENGINE_DOMAIN,
  path,
  body,
  contentType = 'application/json',
  options,
  returnBlob = false,
  errorConfig = {},
}: HttpClientOptions): Promise<DTO.ApiResponse<T>> => {
  const url = `${domain}${path}`;
  const { userAuth } = store.getState().auth;
  const headerContentType = {
    'Content-Type': contentType,
  };
  const tenant = localStorage.getItem('Tenant');
  const headerTenant = {
    'x-tenant-name': tenant || KEYCLOAK_DEFAULT_TENANT || '',
  };
  const headerAuth =
    userAuth && userAuth.id_token
      ? {
          Authorization: `Bearer ${userAuth.id_token}`,
        }
      : null;
  const headerApiGatewayApiVersion = {
    apiVersion: APIGATEWAY_API_VERSION || '',
  };

  const headerApiGatewaySubscriptionKey = {
    'ocp-apim-subscription-key': APIGATEWAY_SUBSCRIPTION_KEY || '',
  };

  const requestHeaders = {
    ...headerContentType,
    ...headerAuth,
    ...headerTenant,
    ...headerApiGatewayApiVersion,
    ...headerApiGatewaySubscriptionKey,
  };
  const decodedJwt =
    (userAuth && userAuth.id_token && jwt.decode(userAuth.id_token)) ||
    undefined;

  if (
    decodedJwt &&
    decodedJwt['exp'] &&
    decodedJwt['exp'] * 1000 < Date.now()
  ) {
    const state = store.getState();
    const refreshToken =
      state &&
      state.auth &&
      state.auth.userAuth &&
      state.auth.userAuth.refresh_token;

    if (refreshToken) {
      const refreshRequestOptions = {
        method: 'POST',
        headers: {
          ...headerContentType,
          ...headerTenant,
          ...headerApiGatewayApiVersion,
          ...headerApiGatewaySubscriptionKey,
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      };
      const result = await fetch(
        `${EXCELENGINE_DOMAIN}/api/v1/userlogin/refreshtoken`,
        refreshRequestOptions
      );
      const payload = await result.json();
      const { data } = payload;
      const newIdToken = data.id_token;

      if (!newIdToken) {
        store.dispatch({ type: UserConstants.LOGOUT });

        throw new ApiError({
          error_code: UserConstants.REFRESH_TOKEN_LIMIT_REACHED,
        });
      }

      store.dispatch({
        type: UserConstants.SET_ID_TOKEN,
        payload: {
          id_token: newIdToken,
        },
      });

      requestHeaders.Authorization = `Bearer ${newIdToken}`;
    }
  }

  if (body) {
    if (body instanceof FormData) {
      delete requestHeaders['Content-Type'];
    } else {
      body = JSON.stringify(body);
    }
  }

  const requestOptions: RequestInit = {
    method,
    headers: requestHeaders,
    body,
    ...options,
  };

  const res = await fetchNetworkErrorWrap(url, requestOptions);
  const { status, headers } = res;

  const { isErrorStatus, isPayloadStatusError, onError, throwAPIError } = {
    ...defaultErrorConfig,
    ...errorConfig,
  };

  if (
    returnBlob ||
    requestHeaders['Content-Type'] === 'application/octet-stream'
  ) {
    const blob = await res.blob();

    if (throwAPIError && isErrorStatus(status)) {
      onError(status, { errorCode: '' });

      throw new ApiError({ errorCode: '' });
    }

    return {
      status,
      payload: { blob } as T & { blob: Blob },
      headers,
    };
  }

  const jsonDataPayload: T = await res.json();

  const response = {
    status,
    payload: jsonDataPayload,
    headers,
  };

  if (
    throwAPIError &&
    (isErrorStatus(status) || isPayloadStatusError(response.payload))
  ) {
    throw new ApiError(response.payload);
  }

  return response;
};

export default httpClient;
