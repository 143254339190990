import { ProductDocsAction, ProductsAction } from '../actions/types';
import { ProductDocsConstant, ProductsConstants } from '../constants';
import { arrayRemoveFirst, arrayReplaceFirst } from '../helpers';

export const initialList: STATES.ProductDocList = {
  isLoading: false,
  docs: [],
  total: 0,
  refreshTrigger: 0,
};

export const initialState: STATES.ProductDocsState = {};

export const productDocs = (
  state = initialState,
  action: ProductDocsAction | ProductsAction
): STATES.ProductDocsState => {
  switch (action.type) {
    case ProductDocsConstant.GET_PRODUCT_DOCS_REQUEST: {
      const { documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          isLoading: true,
        },
      };
    }
    case ProductDocsConstant.GET_PRODUCT_DOCS_SUCCESS: {
      const { docs, total, documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          docs,
          total,
          isLoading: false,
        },
      };
    }
    case ProductDocsConstant.GET_PRODUCT_DOCS_FAILURE: {
      const { documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          isLoading: false,
        },
      };
    }
    case ProductDocsConstant.ADD_DOCUMENT_SUCCESS: {
      const { documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          refreshTrigger: state[documentType].refreshTrigger + 1,
        },
      };
    }
    case ProductDocsConstant.DELETE_DOC_SUCCESS: {
      const { fileName, documentType } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          docs: arrayRemoveFirst(
            state[documentType].docs,
            doc => doc.fileName === fileName
          ),
          refreshTrigger: state[documentType].refreshTrigger + 1,
        },
      };
    }
    case ProductDocsConstant.DOCUMENT_LIST_RESET: {
      const { type } = action.payload;

      return {
        ...state,
        [type]: {
          ...state[type],
          ...initialList,
        },
      };
    }
    case ProductDocsConstant.UPDATE_DOC_FAVORITE_SUCCESS: {
      const { fileName, documentType, favorite } = action.payload;

      return {
        ...state,
        [documentType]: {
          ...state[documentType],
          docs: arrayReplaceFirst(
            state[documentType].docs,
            docs => docs.fileName === fileName,
            docs => ({
              ...docs,
              isStared: favorite,
            })
          ),
        },
      };
    }
    case ProductDocsConstant.CREATE_SECTION_SUCCESS: {
      const { documentType } = action.payload;

      return {
        ...state,
        [documentType]: initialList,
      };
    }
    case ProductDocsConstant.UPDATE_SECTION_SUCCESS: {
      const { oldDocumentType, newDocumentType } = action.payload;

      let tempState = state;
      if (oldDocumentType === newDocumentType) {
        const oldDocList = tempState[oldDocumentType];
        delete tempState[oldDocumentType];
        tempState = {
          ...tempState,
          [newDocumentType]: {
            ...oldDocList,
            refreshTrigger: oldDocList.refreshTrigger + 1,
          },
        };
      } else {
        delete tempState[oldDocumentType];
        tempState = {
          ...tempState,
          [newDocumentType]: initialList,
        };
      }
      return {
        ...tempState,
      };
    }
    case ProductDocsConstant.DELETE_SECTION_SUCCESS: {
      const { documentType } = action.payload;

      const tempState = state;
      delete tempState[documentType];

      return {
        ...tempState,
      };
    }
    case ProductsConstants.GET_PRODUCT_DETAIL_SUCCESS: {
      const { product } = action.payload;

      if (product && product.sections) {
        const tempState = initialState;
        product.sections.forEach(item => {
          tempState[item.name] = initialList;
        });

        return {
          ...tempState,
        };
      }

      return state;
    }
    default:
      return state;
  }
};
