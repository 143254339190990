import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ProductsActions } from '../actions';

const mapStateToAppProps = ({
  products: { selectedProduct, clonedProductName, refreshTrigger },
  shareProducts: { sharingProductName, productShareStatus },
}: STATES.AppState) => ({
  selectedProduct,
  clonedProductName,
  refreshTrigger,
  sharingProductName,
  productShareStatus,
});

const useProductDashboard = (productId: string) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedProduct, clonedProductName, refreshTrigger } = useSelector(
    mapStateToAppProps
  );

  const { pathname } = useLocation();
  const [sharedLink, setSharedLink] = useState<string>('');
  const refreshTriggerRef = useRef(refreshTrigger);

  useEffect(() => {
    return () => {
      dispatch(ProductsActions.cleanSelectedProduct());
    };
  }, [dispatch]);

  useEffect(() => {
    setSharedLink(window.location.href.replace('/dashboard', ''));
  }, []);

  useEffect(() => {
    if (!productId) {
      return;
    }
    if (!selectedProduct || selectedProduct.name !== productId) {
      dispatch(ProductsActions.getProductDetail(productId));
    }
  }, [dispatch, productId, selectedProduct]);

  useEffect(() => {
    if (productId && refreshTriggerRef.current !== refreshTrigger) {
      dispatch(ProductsActions.getProductDetail(productId));
    }

    refreshTriggerRef.current = refreshTrigger;
  }, [dispatch, productId, refreshTrigger]);

  useEffect(() => {
    if (clonedProductName) {
      history.push(`/products/${clonedProductName}`);
    }
  }, [clonedProductName, history]);

  const testCenterKey = pathname.split('/').includes('testcenter')
    ? pathname
    : '';

  return { testCenterKey, sharedLink };
};

export { useProductDashboard };
