import { ClientManagerConstants } from '../constants';
import { ClientManagerService, UserManagerService } from '../services';
import { AlertActions } from './alert.actions';
import { ClientManagerAction, ClientManagerThunkAction } from './types';
import { ApiError, downloadBlob } from '../helpers';

const getAll = (
  page: number,
  pageSize: number,
  pathQuery: string
): ClientManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: ClientManagerConstants.GET_USERGROUPS_REQUEST,
      payload: {
        page,
        pageSize,
      },
    });

    const { payload, status } = await ClientManagerService.getUserGroups({
      page,
      pageSize,
      pathQuery,
    });

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ClientManagerConstants.GET_USERGROUPS_SUCCESS,
      payload: {
        userGroups: payload.data,
        total: payload.count,
        page,
        pageSize,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ClientManagerConstants.GET_USERGROUPS_FAILURE,
      payload: { error: msg },
    });
  }
};

const get = (
  userGroupId: string
): ClientManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: ClientManagerConstants.GET_USERGROUP_REQUEST,
      payload: { userGroupId },
    });

    const { payload, status } = await ClientManagerService.getUserGroup(
      userGroupId
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ClientManagerConstants.GET_USERGROUP_SUCCESS,
      payload: {
        userGroupId,
        userGroup: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ClientManagerConstants.GET_USERGROUP_FAILURE,
      payload: {
        userGroupId,
        error: msg,
      },
    });
  }
};

const deleteUserGroup = (
  userGroupName: string
): ClientManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: ClientManagerConstants.DELETE_USERGROUP_REQUEST,
      payload: { userGroupName },
    });

    const { payload, status } = await ClientManagerService.deleteUserGroup(
      userGroupName
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ClientManagerConstants.DELETE_USERGROUP_SUCCESS,
    });

    dispatch(
      AlertActions.success('ClientManager.table.actions.delete.success', {
        usergroup: userGroupName,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ClientManagerConstants.DELETE_USERGROUP_FAILURE,
      payload: {
        error: msg,
        userGroupName,
      },
    });
  }
};

const select = (userGroup: DTO.UserGroupRule | null): ClientManagerAction => ({
  type: ClientManagerConstants.SELECT_USERGROUP,
  payload: { userGroup },
});

const getGroups = (): ClientManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: ClientManagerConstants.GET_GROUPS_REQUEST,
    });

    const { payload, status } = await UserManagerService.getGroups();
    if (status !== 200 || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ClientManagerConstants.GET_GROUPS_SUCCESS,
      payload: {
        groups: payload.groups,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ClientManagerConstants.GET_GROUPS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const updateUserGroupSettings = (
  updateGroupRule: DTO.UserGroupRule
): ClientManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: ClientManagerConstants.SAVE_USERGROUP_SETTINGS_REQUEST,
    });

    const { payload, status } = await ClientManagerService.saveUserGroup(
      updateGroupRule
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ClientManagerConstants.SAVE_USERGROUP_SETTINGS_SUCCESS,
      payload: {
        userGroup: updateGroupRule,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ClientManagerConstants.SAVE_USERGROUP_SETTINGS_FAILURE,
      payload: {
        error: msg,
      },
    });

    return false;
  }
};
const saveUserGroup = (
  userGroup: DTO.UserGroupRule
): ClientManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: ClientManagerConstants.SAVE_USER_GROUP_REQUEST,
    });

    const { payload, status } = await ClientManagerService.saveUserGroupInfo(
      userGroup
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ClientManagerConstants.SAVE_USER_GROUP_SUCCESS,
    });

    dispatch(AlertActions.success('ClientManager.form.addSuccess'));

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ClientManagerConstants.SAVE_USER_GROUP_FAILURE,
      payload: {
        error: msg,
      },
    });

    return false;
  }
};

const editUserGroup = (
  userGroupRule: DTO.UserGroupRule
): ClientManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: ClientManagerConstants.EDIT_USER_GROUP_REQUEST,
    });

    const { payload, status } = await ClientManagerService.saveUserGroupInfo(
      userGroupRule
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ClientManagerConstants.EDIT_USER_GROUP_SUCCESS,
    });

    dispatch(AlertActions.success('ClientManager.form.updateSuccess'));

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ClientManagerConstants.EDIT_USER_GROUP_FAILURE,
      payload: {
        error: msg,
      },
    });

    return false;
  }
};

const downloadUserGroups = (
  page: number,
  pageSize: number,
  pathQuery: string
): ClientManagerThunkAction => async (dispatch, getState) => {
  try {
    const {
      auth: { userAuth },
    } = getState();

    if (!userAuth) {
      return;
    }

    const { payload, status } = await ClientManagerService.downloadUserGroups(
      {
        page,
        pageSize,
        pathQuery,
      },
      'usergroups.xlsx'
    );

    if (status !== 200) {
      throw new ApiError(payload);
    }

    downloadBlob(payload.blob, 'usergroups.xlsx');
  } catch (error) {
    dispatch(AlertActions.error(error));
  }
};

export const ClientManagerActions = {
  getAll,
  get,
  select,
  deleteUserGroup,
  getGroups,
  saveUserGroup,
  editUserGroup,
  downloadUserGroups,
  updateUserGroupSettings,
};
