import makeRequest from '../helpers/make-request';

const USERSTORE_DOMAIN = process.env.REACT_APP_USERSTORE_DOMAIN;
const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

const getUsers = () => {
  const url = `${USERSTORE_DOMAIN}/users/api/admin/users`;

  return makeRequest<DTO.GetUsersResponse>('GET', url);
};

const getUser = (user_id: string) => {
  const url = `${USERSTORE_DOMAIN}/users/api/admin/users/${user_id}`;

  return makeRequest<{ user: DTO.UserResponse }>('GET', url);
};

const addUser = (
  email: string,
  groupPath: string,
  data: DTO.UserAdditionData
) => {
  const url = `${USERSTORE_DOMAIN}/users/api/admin/users`;

  const body = {
    email,
    group_path: groupPath,
    data: JSON.stringify(data),
  };

  return makeRequest<{ user_id: string }>('POST', url, body);
};

const updateUser = (
  userId: string,
  email: string,
  groupPath: string,
  data: DTO.UserAdditionData
) => {
  const url = `${USERSTORE_DOMAIN}/users/api/admin/users/${userId}`;

  const body = {
    user_id: userId,
    email,
    group_path: groupPath,
    data: JSON.stringify(data),
  };

  return makeRequest('PUT', url, body);
};

const changeUserStatus = (userId: string, status: 'Enabled' | 'Disabled') => {
  const url = `${USERSTORE_DOMAIN}/users/api/admin/users/${userId}/status`;

  const body = { user_id: userId, status };

  return makeRequest('PUT', url, body);
};

const notifyUser = (dictionary: DTO.NotifyUserRequest) => {
  const url = `${EXCELENGINE_DOMAIN}/api/email/notifyuser`;

  return makeRequest('POST', url, dictionary);
};

const addUserToGroup = (email: string, groupPath: string) => {
  const url = `${USERSTORE_DOMAIN}/users/api/admin/users/updates/group`;

  const body = {
    user_email: email,
    group_path: groupPath,
  };

  return makeRequest('PUT', url, body);
};

const getGroups = () => {
  const url = `${USERSTORE_DOMAIN}/users/api/admin/users/groups/`;
  return makeRequest<{ groups: string[] }>('GET', url);
};

export const UserManagerService = {
  getUsers,
  getUser,
  addUser,
  updateUser,
  changeUserStatus,
  notifyUser,
  addUserToGroup,
  getGroups,
};
