import { UserConstants } from '../constants';

const validHierarchicalGroup =
  process.env.REACT_APP_VALID_HIERARCHICAL_GROUP || '';

const getValidRoleAndGroup = (groups: string[]) =>
  groups.reduce(
    (prevRoleAndGroup, currGroup) => {
      const [role, hierarchicalGroup] = currGroup.split(':').slice(0, 2);

      const currentRoleAndGroup =
        UserConstants.USER_ROLES.includes(role) &&
        hierarchicalGroup &&
        validHierarchicalGroup ===
          hierarchicalGroup.substr(0, validHierarchicalGroup.length)
          ? {
              role,
              group: hierarchicalGroup,
            }
          : {
              role: '',
              group: '',
            };

      if (
        currentRoleAndGroup.group &&
        (!prevRoleAndGroup.group ||
          currentRoleAndGroup.role === UserConstants.ROLE_SUPERVISOR)
      ) {
        return currentRoleAndGroup;
      }

      return (
        (prevRoleAndGroup.group && prevRoleAndGroup) || {
          role: '',
          group: '',
        }
      );
    },
    {
      role: '',
      group: '',
    }
  );

export { getValidRoleAndGroup };
