import { ShareProductConstants, ProductsConstants } from '../constants';
import { SharedProductsThunkAction } from './types';
import { AlertActions } from './alert.actions';
import { ProductService } from '../services';
import { ApiError } from '../helpers';

const getSharedProducts = (
  data: DTO.GetProductsRequest
): SharedProductsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ShareProductConstants.GET_SHARED_PRODUCTS_REQUEST,
      payload: { ...data },
    });

    const { payload, status } = await ProductService.getSharedProducts(data);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ShareProductConstants.GET_SHARED_PRODUCTS_SUCCESS,
      payload: {
        ...data,
        products: payload.data,
        total: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ShareProductConstants.GET_SHARED_PRODUCTS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getProductShareState = (productName: string) => ({
  type: ProductsConstants.GET_PRODUCT_SHARE_STATE_SUCCESS,
  payload: {
    productName,
    groups: [],
  },
});

const reseProductShareState = () => ({
  type: ShareProductConstants.PRODUCT_SHARE_STATE_RESET,
});

const updateProductShareState = (
  productName: string,
  groupName: string,
  shared: boolean
): SharedProductsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ShareProductConstants.UPDATE_PRODUCT_SHARE_REQUEST,
      payload: {
        productName,
        groupName,
      },
    });
    // TODO change
    // const service = undefined;
    // const { status, payload } = await service(
    //   `/ProductFactory/${productName}`,
    //   groupName
    // );

    // if (
    //   status !== 200 ||
    //   !['Success', 'OK', 'Ok'].includes(payload.status || '')
    // ) {
    //   throw new ApiError(payload);
    // }

    dispatch({
      type: ShareProductConstants.UPDATE_PRODUCT_SHARE_SUCCESS,
      payload: {
        productName,
        groupName,
        shared,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ShareProductConstants.UPDATE_PRODUCT_SHARE_FAILURE,
      payload: {
        productName,
        groupName,
        error: msg,
      },
    });
  }
};

export const ShareProductActions = {
  getSharedProducts,
  getProductShareState,
  reseProductShareState,
  updateProductShareState,
};
