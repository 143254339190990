import React, { useMemo } from 'react';
import { Table, Icon, Row } from 'antd';
import {
  TableProps,
  TableStateFilters,
  SortOrder,
  ColumnProps,
} from 'antd/lib/table';
import styled from 'styled-components';

const CustomSortContainer = styled(Row)<{ supportOrder?: boolean }>`
  ${({ supportOrder }) => supportOrder && 'padding-right: 35px;'}
  position: relative;
`;

const CustomSortIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: 15px;
  width: 20px;
`;

export const CustomSortTableOnHeaderCell = <
  T extends {},
  TSortBy extends string | undefined | null
>(
  sortBy: TSortBy | undefined,
  sortDir: SortOrder | undefined,
  setSort: (sortBy: TSortBy | undefined, sortDir: SortOrder | undefined) => void
): ColumnProps<T>['onHeaderCell'] => {
  return (column: ColumnProps<T>) => {
    return {
      onClick: () => {
        if (sortBy === column.key) {
          const sortOptions: (SortOrder | undefined)[] = [
            'ascend',
            'descend',
            undefined,
            'ascend',
          ];
          const nextSortDir = sortOptions[sortOptions.indexOf(sortDir) + 1];
          const nextSortBy = nextSortDir ? sortBy : undefined;

          setSort(nextSortBy, nextSortDir);

          return;
        }

        setSort(
          (typeof column.key === 'number'
            ? column.key.toString()
            : column.key) as TSortBy,
          'ascend'
        );
      },
    };
  };
};

const CustomSortIconTable = <T extends {}>(props: TableProps<T>) => {
  const { columns } = props;

  const modifedColumns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return [];
    }

    return columns.map(column => {
      if (!column.title) {
        return column;
      }

      const title: ColumnProps<T>['title'] = (options: {
        filters: TableStateFilters;
        sortOrder?: SortOrder;
        sortColumn?: ColumnProps<T> | null;
      }) => {
        const { sortOrder, sortColumn } = options;

        const columnTitle =
          typeof column.title === 'function'
            ? column.title(options)
            : column.title;

        return (
          <CustomSortContainer
            type="flex"
            align="middle"
            supportOrder={
              !!sortColumn &&
              sortColumn.dataIndex === column.dataIndex &&
              !!sortOrder
            }
          >
            <div>{columnTitle}</div>
            <CustomSortIconContainer>
              {sortColumn &&
                sortColumn.dataIndex === column.dataIndex &&
                sortOrder && (
                  <Icon type={sortOrder === 'ascend' ? 'down' : 'up'} />
                )}
            </CustomSortIconContainer>
          </CustomSortContainer>
        );
      };

      return {
        ...column,
        title,
      };
    });
  }, [columns]);

  return <Table {...props} columns={modifedColumns} />;
};

export { CustomSortIconTable };
