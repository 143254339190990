import React, { NamedExoticComponent, memo, useMemo } from 'react';
import { Progress, Icon } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { colorHexToRgba, formatToLongDateTime } from '../../helpers';
import { IconSvg } from '../Common';

type BackgroundJobItemProps = {
  job: DTO.BackgroundJob;
  onCancel: (job: DTO.BackgroundJob) => void;
};

const Container = styled.div`
  font-size: 12px;
  color: ${({ theme }) => colorHexToRgba(theme.color.gray1, 0.6)};
  border-bottom: thin solid
    ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
  padding: 5px 5px 0 0;

  .title-link {
    &,
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: ${({ theme }) =>
        colorHexToRgba(theme.color.gray1, 0.6)} !important;

      .anticon {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  :last-child {
    border-bottom: none;
  }

  .progress-container {
    display: flex;
    align-items: center;

    .icon-container {
      width: 21px;
      height: 21px;
      margin-left: 10px;
      line-height: 21px;
      text-align: center;

      .anticon {
        font-size: 21px;

        &.anticon-close-circle {
          color: ${({ theme }) => theme.color.red};

          &.inprogress {
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;

            :hover,
            :focus,
            :active {
              color: ${({ theme }) => theme.color.dark};
            }
          }
        }

        &.anticon-check-circle {
          color: ${({ theme }) => theme.color.aqua};
        }
      }
    }

    .ant-progress {
      width: auto;
      flex: 1;

      .ant-progress-bg {
        height: 12px !important;
      }

      .ant-progress-text {
        visibility: collapse;
      }

      .ant-progress-success-bg,
      .ant-progress-bg {
        background-color: ${({ theme }) => theme.color.dark};
      }
    }

    .ant-progress-status-success .ant-progress-bg {
      background-color: ${({ theme }) => theme.color.aqua} !important;
    }

    .ant-progress-status-exception .ant-progress-bg {
      background-color: ${({ theme }) => theme.color.red} !important;
    }
  }
`;

const BackgroundJobItem: NamedExoticComponent<BackgroundJobItemProps> = memo(
  ({ job, onCancel }) => {
    const intl = useIntl();
    const {
      progress,
      id,
      status,
      type,
      completedAt,
      createdAt,
      result,
      data,
    } = job;

    const progressStatus = useMemo(() => {
      switch (status) {
        case 'CANCELLED':
        case 'FAILED': {
          return 'exception';
        }
        case 'COMPLETED': {
          return 'success';
        }
        default: {
          return 'normal';
        }
      }
    }, [status]);

    const desc = useMemo(() => {
      let builtMessage = intl.formatMessage(
        {
          id: `BackgroundJobItem.${status}.${type}`,
        },
        {
          progress,
          duration: moment(createdAt).fromNow(true),
          completedAt: formatToLongDateTime(completedAt || ''),
        }
      );

      if (
        status === 'FAILED' &&
        type === 'UploadFolder' &&
        typeof result === 'string'
      ) {
        const additionError = intl.formatMessage({ id: result });

        builtMessage += ` (${additionError})`;
      }

      return builtMessage;
    }, [status, completedAt, progress, createdAt, type, result, intl]);

    const renderTitle = () => {
      const name = data.Name || id;
      if (status !== 'COMPLETED') {
        return name;
      }

      if (type === 'UploadFolder') {
        return (
          <Link to={`/products/${name}`} className="title-link">
            {name}
          </Link>
        );
      }

      return (
        <a
          className="title-link"
          href={result || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{name}</span>
          <IconSvg type="download" />
        </a>
      );
    };

    return (
      <Container data-testid="job-item">
        <div>{renderTitle()}</div>
        <div className="progress-container">
          <Progress
            percent={progress}
            showInfo={false}
            status={progressStatus}
          />
          <div className="icon-container">
            <Icon
              data-testid={`cancel-job-${id}`}
              type={
                progressStatus === 'success' ? 'check-circle' : 'close-circle'
              }
              className={progressStatus === 'normal' ? 'inprogress' : ''}
              onClick={
                progressStatus === 'normal' ? () => onCancel(job) : undefined
              }
            />
          </div>
        </div>
        <div>{desc}</div>
      </Container>
    );
  }
);

export { BackgroundJobItem };
