import React, { FC, useRef, useEffect } from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import styled from 'styled-components';
import lottie from 'lottie-web/build/player/lottie_light_html';
import { FormattedMessage } from 'react-intl';
import animationData from '../../../assets/logo-animation.json';

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const StyledLottieContainer = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  position: relative;
`;

const StyledSpan = styled.span<{ height: number; width: number; left: number }>`
  top: ${({ height }) => height}px;
  left: ${({ left }) => left}px;
  text-align: center;
  position: absolute;
  width: ${({ width }) => width}px;
  color: black;
`;

const StyledSpin = styled(Spin)`
  max-height: none !important;
  min-height: 100px;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px !important;
    margin-top: -50px !important;
  }
`;

interface LoadingGifProps {
  width?: number;
  height?: number;
  text?: string;
  textHeight?: number;
  textWidth?: number;
  textLeft?: number;
}

type InlineLoadingGifProps = LoadingGifProps;

const LoadingGif: FC<LoadingGifProps> = ({
  width = 100,
  height = 100,
  text = '',
  textHeight = 100,
  textWidth = 300,
  textLeft = -100,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animation =
      ref.current &&
      lottie.loadAnimation({
        container: ref.current,
        renderer: 'html',
        ...defaultLottieOptions,
      });

    return () => {
      animation && animation.destroy();
    };
  }, []);

  return (
    <StyledLottieContainer ref={ref} width={width} height={height}>
      <StyledSpan height={textHeight} width={textWidth} left={textLeft}>
        {text && <FormattedMessage id={text} />}
      </StyledSpan>
    </StyledLottieContainer>
  );
};

const InlineLoadingGifContainer = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
`;

const InlineLoadingGif: FC<InlineLoadingGifProps> = ({
  width = 100,
  height = 100,
}) => (
  <InlineLoadingGifContainer>
    <LoadingGif width={width} height={height} />
  </InlineLoadingGifContainer>
);

interface LoadingIndicatorProps extends SpinProps {
  flexFill?: boolean;
  text?: string;
  textHeight?: number;
  textWidth?: number;
  textLeft?: number;
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  flexFill,
  wrapperClassName,
  text,
  textHeight = 100,
  textWidth = 300,
  textLeft = -150,
  ...spinProps
}) => {
  return (
    <StyledSpin
      {...spinProps}
      indicator={
        <>
          <LoadingGif
            text={text}
            textHeight={textHeight}
            textWidth={textWidth}
            textLeft={textLeft}
          />
        </>
      }
      wrapperClassName={
        flexFill ? `flex-fill ${wrapperClassName}` : wrapperClassName
      }
    />
  );
};

Spin.setDefaultIndicator(<LoadingIndicator />);

export { LoadingIndicator, InlineLoadingGif };
