export const ENGINE_MAX = {
  moduleName: 'ProductEngine',
  ruleName: 'NoOfEngine',
};

export const ENGINE_ADD = {
  moduleName: 'ProductEngine',
  ruleName: 'Add',
};

export const ENGINE_VIEW = {
  moduleName: 'ProductEngine',
  ruleName: 'View',
};

export const ENGINE_EDIT = {
  moduleName: 'ProductEngine',
  ruleName: 'View',
};

export const ENGINE_DOWNLOADSWAGGER = {
  moduleName: 'ProductEngine',
  ruleName: 'DownloadSwagger',
};

export const PRODUCT_MAX = {
  moduleName: 'Product',
  ruleName: 'NoOfProducts',
};

export const PRODUCT_CLONE = {
  moduleName: 'Product',
  ruleName: 'Clone',
};

export const PRODUCT_STATUSCHANGE = {
  moduleName: 'Product',
  ruleName: 'StatusChange',
};

export const REGRESSIONTEST_POST = {
  moduleName: 'ProductRegressionTest',
  ruleName: 'RegressionTest',
};

export const TESTCASEGENERATION_POST = {
  moduleName: 'ProductEngineTestCase',
  ruleName: 'TestCaseGeneration',
};

export const ENGINELOG_AUDITFEATURES = {
  moduleName: 'ProductEngineLog',
  ruleName: 'AuditFeatures',
};

export const BROCHURE_ADD = {
  moduleName: 'ProductDocument',
  ruleName: 'AddBrochure',
};

export const DOCUMENTATION_ADD = {
  moduleName: 'ProductDocument',
  ruleName: 'AddDocumentation',
};

export const FORMBUILDER_PRODUCTTUTORIAL = {
  moduleName: 'ProductEngineFormBuilder',
  ruleName: 'ProductTutorial',
};

export const LOAD_USER_GROUP_RULES_REQUEST = 'LOAD_USER_GROUP_RULES_REQUEST';
export const LOAD_USER_GROUP_RULES_SUCCESS = 'LOAD_USER_GROUP_RULES_SUCCESS';
export const LOAD_USER_GROUP_RULES_FAILURE = 'LOAD_USER_GROUP_RULES_FAILURE';
