import { Userpilot } from 'userpilot';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const userpilotToken = process.env.REACT_APP_USERPILOT_APP_TOKEN;

if (userpilotToken) {
  Userpilot.initialize(userpilotToken);
}

const userPilotSelector = createSelector<
  STATES.AppState,
  STATES.AuthState,
  STATES.UserState,
  { userId: string; userInfo: STATES.UserState['userInfo'] }
>(
  ({ auth }) => auth,
  ({ user }) => user,
  ({ userId }, { userInfo }) => ({ userId, userInfo })
);

const useUserpilot = (productName = '') => {
  const { userInfo, userId } = useSelector(userPilotSelector);
  const { pathname, hash, search } = useLocation();

  useEffect(() => {
    if (!userpilotToken) {
      return;
    }

    if (userInfo) {
      const { displayName } = userInfo;

      Userpilot.identify(userId, {
        name: displayName,
        productName,
      });
    }
  }, [userInfo, userId, productName]);

  useEffect(() => {
    if (!userpilotToken) {
      return;
    }

    Userpilot.reload();
  }, [hash, search, pathname, userInfo]);
};

export default useUserpilot;
