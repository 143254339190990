import { ViewLogsConstant } from '../constants';
import { ViewLogsAction, ViewLogsThunkAction } from './types';
import { EngineService } from '../services';
import { AlertActions } from './alert.actions';
import { ApiError, formatToShortDateTime } from '../helpers';

const getProductEngineLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  uploadDate: string,
  data: DTO.GetProductEngineLogsRequest
): ViewLogsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ViewLogsConstant.GET_ENGINE_VIEW_LOGS,
      payload: { ...data },
    });

    const requestData = { ...data };

    if (
      !requestData.endDate &&
      !requestData.startDate &&
      requestData.searchText
    ) {
      requestData.startDate = formatToShortDateTime(uploadDate);
      requestData.endDate = formatToShortDateTime();
    }

    const { payload, status } = await EngineService.getProductEngineLogs(
      productName,
      serviceName,
      versionId,
      requestData
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ViewLogsConstant.GET_ENGINE_VIEW_LOGS_SUCCESS,
      payload: {
        ...data,
        logs: payload.data,
        total: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ViewLogsConstant.GET_ENGINE_VIEW_LOGS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetEngineLogs = (): ViewLogsAction => ({
  type: ViewLogsConstant.ENGINE_LOGS_RESET,
});

const downloadMatchesEngineLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  startDate: string,
  endDate: string
): ViewLogsThunkAction => (_, state) => {
  const {
    auth: { userAuth },
  } = state();

  if (!userAuth) {
    return;
  }

  const url = EngineService.getDownloadMatchesEngineLogsFileUrl(
    productName,
    serviceName,
    versionId,
    startDate,
    endDate,
    userAuth.id_token
  );

  window.open(url, '_blank');
};

const downloadEngineLogFile = (
  productName: string,
  serviceName: string,
  startDate: string,
  endDate: string,
  engineCallId: string
): ViewLogsThunkAction => (_, state) => {
  const {
    auth: { userAuth },
  } = state();

  if (!userAuth) {
    return;
  }

  const url = EngineService.getDownloadEngineLogFileUrl(
    productName,
    serviceName,
    startDate,
    endDate,
    engineCallId,
    userAuth.id_token
  );

  window.open(url, '_blank');
};

export const EngineLogsActions = {
  getProductEngineLogs,
  resetEngineLogs,
  downloadMatchesEngineLogs,
  downloadEngineLogFile,
};
