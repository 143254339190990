import { ProductsAction } from '../actions/types';
import { ProductsConstants } from '../constants';

const initialState: STATES.ProductChangesState = {
  isLoading: false,
  recentChanges: [],
  nextPageToken: '',
};

export const productChanges = (
  state = initialState,
  action: ProductsAction
): STATES.ProductChangesState => {
  switch (action.type) {
    case ProductsConstants.GET_RECENT_CHANGES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ProductsConstants.GET_RECENT_CHANGES_SUCCESS: {
      const { data, nextPageToken } = action.payload;

      return {
        ...state,
        isLoading: false,
        recentChanges: [...state.recentChanges, ...data],
        nextPageToken,
      };
    }
    case ProductsConstants.GET_RECENT_CHANGES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ProductsConstants.CLEAR_RECENT_CHANGES: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
