import { TutorialConstants } from '../constants';
import { TutorialAction } from '../actions/types';

export const initialState: STATES.TutorialState = {
  isLoadingFormSpec: false,
  formSpec: null,
  serviceName: '',
  fields: {},
  error: '',
};

export const tutorial = (
  state = initialState,
  action: TutorialAction
): STATES.TutorialState => {
  switch (action.type) {
    case TutorialConstants.GET_FORMSPEC_REQUEST: {
      const { serviceName } = action.payload;

      return {
        ...state,
        serviceName,
        isLoadingFormSpec: true,
        error: '',
      };
    }
    case TutorialConstants.GET_FORMSPEC_SUCCESS: {
      const { formSpec, serviceName } = action.payload;

      if (serviceName !== state.serviceName) {
        return state;
      }

      return {
        ...state,
        formSpec,
        isLoadingFormSpec: false,
      };
    }
    case TutorialConstants.CONSTRUCT_TUTORIAL_FORMSPEC_ACTION: {
      const { formSpec } = action.payload;

      return {
        ...state,
        formSpec: {
          ...formSpec,
          isConstructed: true,
        },
      };
    }
    case TutorialConstants.GET_FORMSPEC_FAILURE: {
      const { error, serviceName } = action.payload;

      if (serviceName !== state.serviceName) {
        return state;
      }

      return { ...state, error, isLoadingFormSpec: false };
    }
    case TutorialConstants.RESET_FORMBUILDER: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
