import React, { FC, useRef } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import { FormattedMessage } from 'react-intl';
import { ScrollContainer } from '../ScrollContainer';

interface MetadataModalProps {
  title: string;
  metadata: object | null;
  onClose: () => void;
}

const StyledModal = styled.div`
  .ant-modal {
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: 500px;

      .ant-modal-close {
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon {
          color: ${({ theme }) => theme.color.dark};
          font-size: 30px;
        }
      }

      .ant-modal-title {
        color: ${({ theme }) => theme.color.dark};
        font-size: 24px;
        line-height: 24px;
      }

      .ant-modal-body {
        display: flex;
        flex: 1;
        background-color: ${({ theme }) => theme.color.white};
        flex-direction: column;
        overflow: hidden;
      }
    }
  }
`;

const MetadataModal: FC<MetadataModalProps> = ({
  onClose,
  title,
  metadata,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <StyledModal ref={containerRef} />
      <Modal
        visible={!!metadata}
        footer={null}
        closable
        width={700}
        title={<FormattedMessage id={title} />}
        onCancel={onClose}
        getContainer={() => containerRef.current || document.body}
      >
        <ScrollContainer flexFill>
          <ReactJson
            style={{ width: '100%' }}
            src={metadata || {}}
            name="Metadata"
            collapsed={1}
          />
        </ScrollContainer>
      </Modal>
    </>
  );
};

export { MetadataModal };
