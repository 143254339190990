import jwt, { VerifyOptions } from 'jsonwebtoken';
import { UserConstants } from '../constants';
import makeRequest from './make-request';
import { getValidRoleAndGroup } from './get-valid-role-group';

const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

export const verifyToken = async (userAuth: { id_token: string }) => {
  const jwtVerifyOption: VerifyOptions = {
    algorithms: ['RS256'],
    ignoreExpiration: false,
  };

  try {
    const publicKey = await makeRequest<{ key: string }>(
      'GET',
      `${EXCELENGINE_DOMAIN}/api/v1/userlogin/apikey`
    ).then(({ payload }) => payload.key);
    const decodedJwt = jwt.verify(
      userAuth.id_token || '',
      publicKey,
      jwtVerifyOption
    ) as DTO.UserJWTData;
    return decodedJwt;
  } catch (err) {
    return null;
  }
};

export const decodeToken = (userAuth: { id_token: string }) => {
  const jwtVerifyOption: VerifyOptions = {
    algorithms: ['RS256'],
    ignoreExpiration: false,
  };

  try {
    const decodedJwt = jwt.decode(
      userAuth.id_token || '',
      jwtVerifyOption
    ) as DTO.UserJWTData;
    return decodedJwt;
  } catch (err) {
    return null;
  }
};

export const isAdmin = (decodedJwt: DTO.UserJWTData) => {
  const role =
    decodedJwt &&
    decodedJwt.groups &&
    getValidRoleAndGroup(decodedJwt.groups).role;

  return (!!role && role === UserConstants.ROLE_SUPERVISOR) || true;
};

export const isSuperuser = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.superUserKey &&
      decodedJwt[issuerConfig.superUserKey]) ||
      decodedJwt.is_realm_admin)
  );
};

export const getDisplayName = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.displayNameKey &&
      decodedJwt[issuerConfig.displayNameKey]) ||
      decodedJwt.name)
  );
};

export const getUserName = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.userNameKey &&
      decodedJwt[issuerConfig.userNameKey]) ||
      decodedJwt.preferred_username)
  );
};

export const getUserEmailId = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.emailKey &&
      decodedJwt[issuerConfig.emailKey]) ||
      decodedJwt.preferred_username)
  );
};
