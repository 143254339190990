import { FileManagerAction } from '../actions/types';
import { FileManagerConstants } from '../constants';

const initialState: STATES.AddFileState = {
  isVisible: false,
  folderPath: '',
  uploadPercentage: 0,
  isUploading: false,
  uploadProgress: 0,
  error: null,
  xhrRef: null,
};

export const addFile = (
  state = initialState,
  action: FileManagerAction
): STATES.AddFileState => {
  switch (action.type) {
    case FileManagerConstants.UPLOAD_FILE_OPEN: {
      const { folderPath } = action.payload;

      return {
        ...state,
        folderPath,
        isVisible: true,
      };
    }
    case FileManagerConstants.UPLOAD_FILE_CLOSE: {
      return {
        ...state,
        isVisible: false,
      };
    }

    case FileManagerConstants.UPLOAD_FILE_START: {
      return {
        ...state,
        isUploading: true,
        uploadProgress: 5,
      };
    }
    case FileManagerConstants.UPLOAD_FILE_XHR_REF: {
      const { xhrRef } = action.payload;

      return {
        ...state,
        xhrRef,
      };
    }
    case FileManagerConstants.UPLOAD_FILE_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        uploadProgress,
      };
    }
    case FileManagerConstants.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        isUploading: false,
        uploadProgress: 100,
      };
    }
    case FileManagerConstants.UPLOAD_FILE_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        isUploading: false,
        error,
      };
    }
    case FileManagerConstants.UPLOAD_FILE_RESET: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
