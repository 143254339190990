import { EngineAction } from '../actions/types';
import { EngineConstants } from '../constants';

const initialState: STATES.RegressionTestState = {
  error: null,
  testResult: null,
  isUploading: false,
  startUploadTime: 0,
  endUploadTime: 0,
  uploadProgress: 0,
  startExecuteTime: 0,
  endExecuteTime: 0,
  executeProgress: 0,
  xhrRef: null,
  isDownloading: false,
};

export const regressionTest = (
  state = initialState,
  action: EngineAction
): STATES.RegressionTestState => {
  switch (action.type) {
    case EngineConstants.REGRESSION_TEST_UPLOAD_START: {
      return {
        ...initialState,
        isUploading: true,
        startUploadTime: new Date().getTime(),
        uploadProgress: 5,
      };
    }

    case EngineConstants.REGRESSION_TEST_UPLOAD_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        uploadProgress,
      };
    }

    case EngineConstants.REGRESSION_TEST_EXECUTE_START: {
      const now = new Date().getTime();

      return {
        ...state,
        uploadProgress: 100,
        endUploadTime: now,
        startExecuteTime: now,
        executeProgress: 5,
      };
    }

    case EngineConstants.REGRESSION_TEST_EXECUTE_PROGRESS: {
      const { executeProgress } = action.payload;

      return {
        ...state,
        executeProgress,
      };
    }

    case EngineConstants.REGRESSION_TEST_SUCCESS: {
      const { result } = action.payload;
      const { startUploadTime, endUploadTime, startExecuteTime } = state;

      const endExecuteTime = new Date().getTime();
      const uploadTime = endUploadTime - startUploadTime;
      const executeTime = endExecuteTime - startExecuteTime;

      result.resultSummary.uploadTimeLocal = uploadTime;
      result.resultSummary.testingTimeLocal = executeTime;

      return {
        ...state,
        isUploading: false,
        uploadProgress: 100,
        executeProgress: 100,
        endExecuteTime,
        testResult: result,
      };
    }

    case EngineConstants.REGRESSION_TEST_UPLOAD_XHR_REF: {
      const { xhrRef } = action.payload;

      return {
        ...state,
        xhrRef,
      };
    }

    case EngineConstants.REGRESSION_TEST_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        isUploading: false,
        error,
      };
    }

    case EngineConstants.REGRESSION_TEST_RESULT_DOWNLOAD: {
      return {
        ...state,
        isDownloading: true,
      };
    }

    case EngineConstants.REGRESSION_TEST_RESULT_DOWNLOAD_SUCCESS:
    case EngineConstants.REGRESSION_TEST_RESULT_DOWNLOAD_FAILURE: {
      return {
        ...state,
        isDownloading: false,
      };
    }

    case EngineConstants.REGRESSION_TEST_RESET:
      return { ...initialState };

    default:
      return state;
  }
};
