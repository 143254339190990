import styled from 'styled-components';
import { Col } from 'antd';

const OneThirdCol = styled(Col)`
  position: relative;
  flex: 0 0 auto;
  float: left;
  padding-right: 0;
  padding-left: 0;
  width: 33.33%;
`;

const TwoThirdCol = styled(Col)`
  position: relative;
  flex: 0 0 auto;
  float: left;
  padding-right: 0;
  padding-left: 0;
  width: 66.66%;
`;

export { OneThirdCol, TwoThirdCol };
