import { LanguageConstants } from '../constants';
import { LanguageAction } from '../actions/types';
import { createNewIntl } from '../helpers';

export const initialState: STATES.LanguageState = {
  currentLanguage: navigator.language,
  messages: {},
  intl: createNewIntl(navigator.language, {}),
};

export const language = (
  state = initialState,
  action: LanguageAction
): STATES.LanguageState => {
  switch (action.type) {
    case LanguageConstants.SET_LANGUAGE_MESSAGES: {
      const {
        currentLanguage,
        messages,
        configProviderLocale,
      } = action.payload;

      return {
        ...state,
        currentLanguage,
        messages,
        intl: createNewIntl(currentLanguage, messages),
        configProviderLocale,
      };
    }
    default:
      return state;
  }
};
