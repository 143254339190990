import { Button, Icon, Input } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { SearchInput } from '../SearchInput';

const StyledSearchInput = styled(SearchInput)`
  && {
    width: 230px;
  }
`;

const StyledFilterBarButton = styled(Button)<{ active?: boolean }>`
  && {
    border: none;
    background-color: transparent;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 5px;

    .anticon {
      font-size: 40px;
      color: ${({ theme }) => theme.vars.primaryColor};
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;

      .anticon {
        color: ${({ theme }) => theme.color.dark};
      }
    }
  }
`;

interface CallapsibleSearchInputProps {
  className?: string;
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onSubmit?: () => void;
}

const CollapsibleSearchInput: FC<CallapsibleSearchInputProps> = ({
  placeholder,
  onChange,
  onSubmit,
  value,
  className,
}) => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const searchInputRef = useRef<Input>(null);
  const intl = useIntl();

  const searchInputOnBlur = () => {
    if (!value) {
      setShowSearchInput(false);
    }
  };

  const searchIconOnClick = () => {
    setShowSearchInput(true);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showSearchInput]);

  const resovledPlaceholder = placeholder
    ? intl.formatMessage({ id: placeholder, defaultMessage: placeholder })
    : '';

  return showSearchInput ? (
    <StyledSearchInput
      searchInputRef={searchInputRef}
      placeholder={resovledPlaceholder}
      title={resovledPlaceholder}
      value={value}
      suffix={<Icon type="search" />}
      onChange={onChange}
      size="large"
      onBlur={searchInputOnBlur}
      onSubmit={onSubmit}
      className={className}
    />
  ) : (
    <StyledFilterBarButton
      className={className}
      data-testid="search-btn"
      onClick={searchIconOnClick}
    >
      <Icon type="search" />
    </StyledFilterBarButton>
  );
};

export { CollapsibleSearchInput };
