export const GET_ROADMAP_PRODUCTS_REQUEST = 'GET_ROADMAP_PRODUCTS_REQUEST';
export const GET_ROADMAP_PRODUCTS_SUCCESS = 'GET_ROADMAP_PRODUCTS_SUCCESS';
export const GET_ROADMAP_PRODUCTS_FAILURE = 'GET_ROADMAP_PRODUCTS_FAILURE';

export const UPDATE_PRODUCT_LAUNCH_DATE_REQUEST =
  'UPDATE_PRODUCT_LAUNCH_DATE_REQUEST';
export const UPDATE_PRODUCT_LAUNCH_DATE_SUCCESS =
  'UPDATE_PRODUCT_LAUNCH_DATE_SUCCESS';
export const UPDATE_PRODUCT_LAUNCH_DATE_FAILURE =
  'UPDATE_PRODUCT_LAUNCH_DATE_FAILURE';
