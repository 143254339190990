import React, { memo, ReactNode, NamedExoticComponent, useRef } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Tabs } from 'antd';
import { TabsProps as AntdTabsProps } from 'antd/lib/tabs';
import { ScrollContainer } from '../ScrollContainer';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)<{
  scrollable: boolean;
  tabWidth: number;
}>`
  margin-top: 20px;

  .ant-tabs-card-content {
    background: ${({ theme }) => theme.vars.tabContentBg};
    height: ${({ scrollable }) => (scrollable ? '728px' : 'auto')};
    padding: 40px;
    border: 1px solid ${({ theme }) => theme.vars.primaryColor};
    border-top-width: 0;
  }

  .tab-pane-container {
    flex: 1;
  }

  .ant-tabs-card-content {
    display: flex;
    flex-direction: column;

    .ant-tabs-tabpane-active {
      display: flex;
      flex-direction: column;
      flex: 1 1 !important;
      overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'visible')};
    }
  }

  --custom-tabs-fixed-tab-width: ${({ tabWidth }) => tabWidth}px;
  --custom-tabs-arrow-btn-size: 44px;
  --custom-tabs-arrow-btn-border-radius: 3px;

  .ant-tabs-nav-container {
    padding-left: 0;
    max-width: calc(
      var(--custom-tabs-fixed-tab-width) * 5 + var(--custom-tabs-arrow-btn-size)
    );
    padding-right: var(--custom-tabs-arrow-btn-size);

    .ant-tabs-tab-arrow-show {
      width: var(--custom-tabs-arrow-btn-size);
      height: var(--custom-tabs-arrow-btn-size);
      background: ${({ theme }) => theme.color.aqua};
      border-radius: var(--custom-tabs-arrow-btn-border-radius)
        var(--custom-tabs-arrow-btn-border-radius) 0 0;
      color: ${({ theme }) => theme.color.white};

      .anticon {
        font-size: 25px !important;
      }

      .ant-tabs-tab-prev-icon,
      .ant-tabs-tab-next-icon {
        height: calc(var(--custom-tabs-arrow-btn-size) / 2);
      }
    }

    .ant-tabs-tab-btn-disabled {
      display: none;
    }

    .ant-tabs-tab-next,
    .ant-tabs-tab-prev {
      right: 0;
      top: 0;
      left: auto;
    }
  }

  .ant-tabs-nav-wrap {
    max-width: calc(
      var(--custom-tabs-fixed-tab-width) * 5 + var(--custom-tabs-arrow-btn-size)
    );

    .ant-tabs-tab {
      width: var(--custom-tabs-fixed-tab-width);
      padding-left: 5px !important;
      padding-right: 5px !important;
      text-align: center;
    }
  }
`;

const TabTitleContainer = styled.div`
  > span {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

interface Tab {
  view: ReactNode | null;
  name: ReactNode;
  key: string;
}

export interface CustomTabsProps {
  className?: string;
  tabs: { [key: string]: Tab };
  tabBarExtraContent?: ReactNode;
  topCommonView?: ReactNode;
  onChange?: (key: string) => void;
  activeKey?: string;
  commonView?: ReactNode;
  scrollable?: boolean;
  defaultActiveKey?: string;
  tabWidth?: number;
  onNextClick?: () => void;
  onPrevClick?: () => void;
}

const CustomTabs: NamedExoticComponent<CustomTabsProps> = memo(
  ({
    tabs,
    tabBarExtraContent,
    onChange,
    commonView,
    className,
    activeKey,
    defaultActiveKey,
    scrollable = true,
    tabWidth = 130,
    onNextClick,
    onPrevClick,
    topCommonView,
  }) => {
    const intl = useIntl();
    const tabsRef = useRef<Tabs | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const tabProps: AntdTabsProps = {
      className,
      tabBarExtraContent,
      type: 'card',
    };

    if (activeKey) {
      tabProps.activeKey = activeKey;
    }

    if (defaultActiveKey) {
      tabProps.defaultActiveKey = defaultActiveKey;
    }

    const tabsOnChange = (nextActiveKey: string) => {
      onChange && onChange(nextActiveKey);

      const idx = Object.keys(tabs).indexOf(nextActiveKey);

      let buttonSelector = '';

      if (idx > 2) {
        buttonSelector = '.ant-tabs-tab-next:not(.ant-tabs-tab-btn-disabled)';
      } else {
        buttonSelector = '.ant-tabs-tab-prev:not(.ant-tabs-tab-btn-disabled)';
      }

      const button =
        containerRef.current &&
        (containerRef.current.querySelector(buttonSelector) as HTMLElement);

      button && button.click();
    };

    return (
      <div ref={containerRef}>
        <StyledTabs
          {...tabProps}
          scrollable={scrollable}
          className={`${className} ${activeKey || ''}`}
          onChange={tabsOnChange}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
          tabWidth={tabWidth}
          ref={tabsRef}
        >
          {Object.values(tabs).map(({ name, key, view }) => {
            const tabTitle =
              typeof name === 'string'
                ? intl.formatMessage({ id: name })
                : name;

            return (
              <TabPane
                tab={
                  <TabTitleContainer
                    title={typeof tabTitle === 'string' ? tabTitle : undefined}
                  >
                    <span>{tabTitle}</span>
                  </TabTitleContainer>
                }
                key={key}
                className={key}
              >
                {scrollable ? (
                  <>
                    {topCommonView}
                    <ScrollContainer className="tab-pane-container">
                      {view}
                    </ScrollContainer>
                  </>
                ) : (
                  view
                )}

                {commonView && (
                  <div className="tab-pane-common-view">{commonView}</div>
                )}
              </TabPane>
            );
          })}
        </StyledTabs>
      </div>
    );
  }
);

export { CustomTabs };
